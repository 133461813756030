import React from "react";
import styles from "../styles/CtaOverlayBanner.module.css";
import Skeleton from "react-loading-skeleton";
import Button from "./button";
import { CTAOverlayBannerProps } from "../typescript/components";

export default function CTAOverlayBanner({
  cta_overlay_banner,
}: {
  cta_overlay_banner: CTAOverlayBannerProps;
}) {
  const { title, subtitle, button_1, button_2, image } = cta_overlay_banner;
  return (
    <div
      className={styles.wrapper}
      style={{
        backgroundImage: image && image?.media_file?.url && `url(${image?.media_file?.url})`,
      }}
    >
      <div className={styles.content}>
        <div className={styles.text}>
          {title ? (
            <h3 className={styles.title}>{title}</h3>
          ) : (
            <Skeleton width={100} />
          )}
          {subtitle ? (
            <h5 className={styles.subtitle}>{subtitle}</h5>
          ) : (
            <Skeleton width={100} />
          )}
        </div>
        {(button_1 || button_2) && (
          <div className={styles.ctaGroup}>
            {button_1?.text && (
              <Button
                text={button_1?.text ?? button_1?.link?.title}
                showIcon
                propWidth="250px"
                propBorderColor="var(--brand-primary-500)"
                propBackgroundColor="transparent"
                propHoverBackgroundColor="var(--brand-primary-500)"
                propTextColor="var(--brand-primary-500)"
                propHoverText="var(--white)"
                link={button_1?.link?.href}
              />
            )}
            {button_2?.text && (
              <Button
                text={button_2?.text ?? button_2?.link?.title}
                showIcon
                propWidth="250px"
                propBorderColor="var(--charcoal-500)"
                propBackgroundColor="transparent"
                propHoverBackgroundColor="var(--charcoal-500)"
                propTextColor="var(--charcoal-500)"
                propHoverText="var(--white)"
                link={button_2?.link?.href}
              />
            )}
          </div>
        )}
        {}
      </div>
    </div>
  );
}
