import React, { useState } from "react";
import styles from "../styles/VerticalCard.module.css";
import { VerticalCard as VerticalCardProp } from "../typescript/components";

export default function VerticalCard({
  vertical_card,
  isCardTeamStyle,
}: {
  vertical_card: VerticalCardProp;
  isCardTeamStyle?: boolean;
}) {
  const [isHovered, setIsHovered] = useState(false);
  const appendWidthParam = (url: string, width: number) => {
    try {
      const urlObj = new URL(url);
      urlObj.searchParams.set("width", width.toString());
      return urlObj.toString();
    } catch (error) {
      console.error("Invalid URL:", error);
      return url;
    }
  };

  const iconSrc =
    isHovered && vertical_card.icon_hover && vertical_card.icon_hover.url
      ? vertical_card.icon_hover.url
      : vertical_card.icon && vertical_card.icon.url
        ? vertical_card.icon.url
        : "";

  return (
    <div
      className={[
        styles.verticalCard,
        isCardTeamStyle ? styles.cardTeamWrapper : "",
      ]
        .filter(Boolean)
        .join(" ")}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        if (vertical_card.link && vertical_card.link.href) {
          window.location.href = vertical_card.link.href;
        }
      }}
      style={{
        cursor:
          vertical_card.link && vertical_card.link.href ? "pointer" : "default",
      }}
    >
      {vertical_card.background_image && vertical_card.background_image.url && (
        <img
          className={styles.backgroundImage}
          alt={vertical_card.background_image.description || "Background Image"}
          src={appendWidthParam(vertical_card.background_image.url, 850)}
        />
      )}
      <div className={styles.content}>
        {iconSrc && (
          <img
            className={styles.icon}
            loading="lazy"
            alt={vertical_card.icon?.description || "Icon"}
            src={iconSrc}
          />
        )}
        <b className={styles.description}>{vertical_card.title}</b>
      </div>
      {(isHovered || isCardTeamStyle) && vertical_card.slogan && (
        <span className={styles.slogan}>{vertical_card.slogan}</span>
      )}
    </div>
  );
}
