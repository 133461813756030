import React, { useRef, useEffect, useState } from "react";
import {
  generateUniqueId,
  getRandomItems,
  handleScrollToHash,
} from "../helper/utils";
import styles from "../styles/ResourceGrid.module.css";
import { Swiper as SwiperType } from "swiper/types"; // Import SwiperType for type checking
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { ResourceGrid as ResourceGridProp } from "../typescript/components";
import CaseStudyGrid from "./case-study-grid";
import { getAllResources } from "../helper";
import BlogGrid from "./blog-grid";
import ResourceListGrid from "./resource-list-grid";

interface ResourceListProps {
  resource_grid: ResourceGridProp;
  taxonomies?: [{ taxonomy_uid: string; term_uid: string }];
}

export default function ResourceList({
  resource_grid,
  taxonomies,
}: ResourceListProps) {
  const [localResourceGrid, setLocalResourceGrid] = useState(resource_grid); // Local state for updated grid
  const prevRef = useRef<HTMLButtonElement | null>(null);
  const nextRef = useRef<HTMLButtonElement | null>(null);
  const swiperRef = useRef<SwiperType | null>(null); // Properly type the Swiper instance

  useEffect(() => {
    const fetchResources = async () => {
      if (
        resource_grid.auto_resources ||
        !resource_grid.featured_resources ||
        resource_grid.featured_resources.length === 0
      ) {
        try {
          const resources = await getAllResources(taxonomies);
          // Flatten the resources array if it contains nested arrays
          const flattenedResources = resources.flat(); // Default flat level of 1
          // Update only the local copy of the resource grid with the fetched resources
          setLocalResourceGrid((prevGrid) => ({
            ...prevGrid,
            featured_resources: getRandomItems(flattenedResources, 3),
          }));
        } catch (error) {
          console.error("Error fetching resources:", error);
        }
      }
    };

    fetchResources();
  }, [resource_grid.auto_resources, taxonomies]);

  useEffect(() => {
    if (location.hash) {
      handleScrollToHash(location.hash.substring(1));
    }
  }, [location.hash, resource_grid]);

  const renderCaseStudyGrid = () => {
    return (
      <CaseStudyGrid
        key="CaseStudyHero"
        resource_grid={localResourceGrid}
        taxonomies={taxonomies}
      />
    );
  };

  const renderResourceGrid = () => {
    return (
      <ResourceListGrid
        key="ResourceListGrid"
        resource_grid={localResourceGrid}
        taxonomies={taxonomies}
      />
    );
  };

  const renderBlogGrid = () => {
    return (
      <BlogGrid
        key="BlogGrid"
        resource_grid={localResourceGrid}
        taxonomies={taxonomies}
      />
    );
  };

  const renderContentByComponentType = () => {
    switch (localResourceGrid.component_type) {
      case "Blog Grid":
        return renderBlogGrid();
      case "Resource Grid":
        return renderResourceGrid();
      case "Case Study Grid":
        return renderCaseStudyGrid();
      default:
        return renderBlogGrid();
    }
  };

  useEffect(() => {
    if (swiperRef.current) {
      const swiper = swiperRef.current;

      if (swiper.params && swiper.params.navigation) {
        swiper.params.navigation.prevEl = prevRef.current;
        swiper.params.navigation.nextEl = nextRef.current;
        swiper.navigation.destroy();
        swiper.navigation.init();
        swiper.navigation.update();
      }
    }
  }, [localResourceGrid]);

  return (
    <div
      id={generateUniqueId(localResourceGrid.anchor || localResourceGrid.title)}
      className={styles.resourceGrid}
    >
      {renderContentByComponentType()}
    </div>
  );
}
