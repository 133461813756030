import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getWhitePaperDetail } from "../helper";
import { useLivePreviewCtx } from "../context/live-preview-context-provider";
import { WhitePaperRes } from "../typescript/pages";
import styles from "../styles/WhitePaper.module.css";
import HeroBanner from "../components/hero-banner";
import Helmet from "react-helmet";
import { HeroBanner as HeroBannerProp } from "../typescript/components";
import HeroTitle from "../components/hero-title";
import {
  EntryProps,
  HeroTitle as HeroTitleProp,
} from "../typescript/components";

export default function WhitePaper({
  entry,
}: {
  entry: ({ page }: EntryProps) => void;
}) {
  const lpTs = useLivePreviewCtx();
  const { whitePaperId } = useParams();
  const history = useNavigate();
  const [getEntry, setEntry] = useState({
    whitePaper: {} as WhitePaperRes,
  });
  const [error, setError] = useState(false);

  async function fetchData() {
    try {
      const entryUrl = whitePaperId
        ? `/resources/white-paper/${whitePaperId}`
        : "/";
      const whitePaper = await getWhitePaperDetail(entryUrl);
      if (!whitePaper) setError(true);
      setEntry({ whitePaper });
      entry({ whitePaper: [whitePaper] });
    } catch (error) {
      console.error(error);
      setError(true);
    }
  }

  useEffect(() => {
    fetchData();
    if (error) history("/404");
  }, [whitePaperId, lpTs, error]);

  const { whitePaper } = getEntry;

  const heroBannerData = {
    banner_eyebrow: "WHITE PAPER",
    banner_title: whitePaper.title,
    banner_title_alt: "",
    display_options: {
      align_image: "Right",
      align_text: "Left",
      background_color: "Dark",
      breadcrumb: true,
      show_button: false,
      split_50_50: true,
    },
    header_tag_type: "H1",
    //banner_description: "",
    //banner_logo: "",
    banner_media: whitePaper.featured_image,
  } as HeroBannerProp;

  const heroTitleData = {
    align_text: "Left",
    bottom_border: true,
    content: "",
    eyebrow: "WHITE PAPER",
    header_tag_type: "H1",
    show_breadcrumb: true,
    title: whitePaper.title,
  } as HeroTitleProp;

  const enable_search_indexing = whitePaper?.seo?.enable_search_indexing;
  const meta_title = whitePaper?.seo?.meta_title;
  const meta_description = whitePaper?.seo?.meta_description;
  const keywords = whitePaper?.seo?.keywords;

  return (
    <>
      <Helmet>
        {meta_title && <title>{meta_title}</title>}
        {meta_description && (
          <meta name="description" content={meta_description} />
        )}
        {keywords && <meta name="keywords" content={keywords} />}
        <meta
          name="robots"
          content={
            enable_search_indexing ? "index, follow" : "noindex, nofollow"
          }
        />
      </Helmet>
      <div className={styles.outerWrapper}>
        {whitePaper.featured_image && whitePaper.featured_image.url ? (
          <HeroBanner
            key="contactPageTitle"
            hero_banner={heroBannerData}
          ></HeroBanner>
        ) : (
          <HeroTitle
            key="contactPageTitle"
            hero_title={heroTitleData}
          ></HeroTitle>
        )}
        <div className={styles.moduleWrapper}>
          <div className={styles.root}>
            <section className={styles.virtualTour}>
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: whitePaper.content,
                }}
              />
            </section>
            <div className={styles.divider} />
            <div className={styles.taxonomyWrapper}></div>
          </div>
        </div>
      </div>
    </>
  );
}
