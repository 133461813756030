import React, { useEffect } from "react";
import styles from "../styles/TabBar.module.css";
import { TabBarProps } from "../typescript/components";
import { generateUniqueId, handleScrollToHash } from "../helper/utils";

export default function TabBar({ tab_bar }: { tab_bar: TabBarProps }) {
  useEffect(() => {
    if (location.hash) {
      handleScrollToHash(location.hash.substring(1), 110);
    }
  }, [location.hash, tab_bar]);

  const handleScroll = (id: string) => {
    location.hash = id;
  };

  return (
    <div className={styles.tabContainer}>
      <div className={styles.tabsFlexible}>
        <div className={styles.horizontalTabWrapper}>
          {tab_bar.tabs.map((tab, index) => (
            <div key={index} className={styles.horizontalTab}>
              <div
                onClick={() =>
                  handleScroll(generateUniqueId(tab.anchor, "anchor"))
                }
                className={styles.tab}
              >
                {tab.title}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
