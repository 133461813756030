import React, { useEffect, useRef } from "react";
import "swiper/css/navigation";
import "swiper/css/pagination";
import styles from "../styles/CaseStudyGrid.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper/types";
import { Chevron } from "./icon/chevron";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { ResourceGrid as ResourceGridProp } from "../typescript/components";
import Button from "./button";
import WistiaVideo from "./wistia-video";
export default function CaseStudyGrid({
  resource_grid,
  taxonomies,
}: {
  resource_grid: ResourceGridProp;
  taxonomies?: [{ taxonomy_uid: string; term_uid: string }];
}) {
  const prevRef = useRef<HTMLButtonElement | null>(null);
  const nextRef = useRef<HTMLButtonElement | null>(null);
  const swiperRef = useRef<SwiperType | null>(null);
  const paginationRef = useRef<HTMLDivElement | null>(null);
  const slidesPerView = 1;
  const totalSlides = resource_grid.featured_resources.length;

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.params) {
      const navigation = swiperRef.current.params.navigation;

      if (navigation) {
        navigation.prevEl = prevRef.current;
        navigation.nextEl = nextRef.current;

        swiperRef.current.navigation?.destroy();
        swiperRef.current.navigation?.init();
        swiperRef.current.navigation?.update();
      }
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.blogGridHeading}>
        <h3 className={styles.blogGridEyebrow}>
          {resource_grid.eyebrow.toUpperCase()}
        </h3>
        <h2 className={styles.blogGridTitle}>{resource_grid.title}</h2>
      </div>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        slidesPerView={slidesPerView}
        spaceBetween={0}
        className={`${styles.carousel} case-study-grid-swiper`}
        loop={true}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        pagination={{
          el: `.${styles.pagination}`,
          clickable: true,
          dynamicBullets: true,
        }}
      >
        {resource_grid.featured_resources?.length &&
          resource_grid.featured_resources?.map((slide, index) => (
            <SwiperSlide key={index}>
              <div className={styles.contentCarousel}>
                <div className={styles.contentText}>
                  <h3
                    className={styles.titleCarousel}
                    dangerouslySetInnerHTML={{
                      __html: slide?.title,
                    }}
                  ></h3>
                  {slide?.summary && (
                    <div
                      className={styles.descriptionCarousel}
                      dangerouslySetInnerHTML={{
                        __html: slide?.summary,
                      }}
                    ></div>
                  )}
                  <Button
                    text="View Case Study"
                    showIcon
                    propWidth="250px"
                    propBackgroundColor="var(--primary-500)"
                    propHoverBackgroundColor="var(--white)"
                    propTextColor="var(--white)"
                    propHoverText="var(--primary-500)"
                    link={slide.url}
                  />
                </div>
                {slide?.featured_image && (
                  <div className={styles.contentImg}>
                    {slide?.wistia ? (
                      <WistiaVideo embedUrl={slide.wistia} responsive={true} />
                    ) : (
                      <img
                        src={slide?.featured_image?.url}
                        alt={slide?.featured_image?.description}
                      />
                    )}
                  </div>
                )}
              </div>
            </SwiperSlide>
          ))}
        {totalSlides > 1 && (
          <div className={styles.navigationWrapper}>
            <button ref={prevRef} className={styles.prevButton}>
              <Chevron
                direction="left"
                color="var(--chevron-color, var(--charcoal-500))"
                size={20}
              />
            </button>
            <div ref={paginationRef} className={styles.pagination}></div>
            <button ref={nextRef} className={styles.nextButton}>
              <Chevron
                direction="right"
                color="var(--chevron-color, var(--charcoal-500))"
                size={20}
              />
            </button>
          </div>
        )}
      </Swiper>
    </div>
  );
}
