import React, { useState, useEffect } from "react";
import { GridColDef } from "@mui/x-data-grid";
import Papa, { ParseResult } from "papaparse";
import styles from "../styles/ProductSpecs.module.css";
import { ProductSpecs as ProductSpecsProp } from "../typescript/components";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "./customized-mui/table";
import CustomizedCheckbox from "./customized-mui/checkbox";
import { ROWS_PER_PAGE } from "../typescript/constants";

export default function ProductDisplayBanner({
  product_specs,
}: {
  product_specs: ProductSpecsProp;
}) {
  const [rows, setRows] = useState<object[]>([]);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if (product_specs.specification_file) {
      fetch(product_specs.specification_file.url)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.text(); // Get the CSV content as text
        })
        .then((csvText: string) => {
          parseCSV(csvText);
        })
        .catch((error) => {
          console.error("There was a problem with the fetch operation:", error);
        });
    }
  }, [product_specs.specification_file]);

  const parseCSV = (csvText: string) => {
    Papa.parse<{ [key: string]: string }>(csvText, {
      header: true,
      skipEmptyLines: true,
      complete: function (results: ParseResult<{ [key: string]: string }>) {
        setRows(results.data.map((row, index) => ({ id: index, ...row })));

        const firstRow = results.data[0];
        if (firstRow) {
          const columns: GridColDef[] = Object.keys(firstRow).map((key) => ({
            field: key,
            headerName: key,
            width: 150, // Adjust column width as needed
          }));
          setColumns(columns);
        }
      },
      error: function (error: Error) {
        // General Error type
        console.error("Error parsing CSV:", error);
      },
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div className={styles.root}>
      <section className={styles.content}>
        <h2 className={styles.overview}>{product_specs.title}</h2>
        <div style={{ height: 500, width: "100%" }}>
          <Paper sx={{ width: "100%" }}>
            <TableContainer sx={{ maxHeight: 500 }}>
              <Table aria-label="product table" stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableCell></StyledTableCell>
                    {columns.map((col: any, index) => (
                      <StyledTableCell key={`head-${index}`} variant="head">
                        {col?.headerName}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any, index) => (
                      <StyledTableRow key={row.name}>
                        <StyledTableCell variant="body">
                          <CustomizedCheckbox />
                        </StyledTableCell>
                        {columns.map((col) => (
                          <StyledTableCell
                            variant="body"
                            align="left"
                            key={`cell-${index}-${col.field}`}
                          >
                            {row[col.field]}
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              sx={{ display: "block" }}
              className="pagination-product"
              rowsPerPageOptions={ROWS_PER_PAGE}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </section>
    </div>
  );
}
