import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
import BottomFooter from "./bottom-footer";
import DevTools from "./devtools";
import { getHeaderRes, getFooterRes, getBottomFooterRes } from "../helper";
import { getLocales, onEntryChange } from "../sdk/entry";
import { EntryProps } from "../typescript/components";
import {
  BottomFooterRes,
  FooterRes,
  HeaderRes,
  NavigationMenu,
  PageReference,
} from "../typescript/response";
import NotificationBar from "./notification-bar";
import { useTranslation } from "react-i18next";

export default function Layout({ entry }: { entry: EntryProps }) {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const [layout, setLayout] = useState({
    header: {} as HeaderRes,
    footer: {} as FooterRes,
    bottomFooter: {} as BottomFooterRes,
    navHeaderList: [] as NavigationMenu[],
    navFooterList: [] as PageReference[],
  });
  const [error, setError] = useState(false);

  const mergeObjs = (...objs: any) => Object.assign({}, ...objs);
  const jsonObj = mergeObjs(
    { header: layout.header },
    { footer: layout.footer },
    { bottomFooter: layout.bottomFooter },
    entry
  );

  async function fetchData() {
    try {
      const header = await getHeaderRes();
      const footer = await getFooterRes();
      const bottomFooter = await getBottomFooterRes();
      //const allEntries = await getAllEntries();

      if (!header || !footer || !bottomFooter) {
        throw new Error("Missing layout data");
      }

      const navHeaderList = [...header.navigation_menu];
      const navFooterList = [...footer.navigation_menu];

      const languages = await getLocales();

      header.currentLocale = i18n.language;
      header.languages = languages;

      setLayout({
        header,
        footer,
        bottomFooter,
        navHeaderList,
        navFooterList,
      });
    } catch (error) {
      setError(true);
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    fetchData();
    onEntryChange(fetchData);
  }, [i18n.language]);

  useEffect(() => {
    if (error) {
      console.error("Error loading layout data");
      navigate("/error");
    }
  }, [error, navigate]);

  return (
    <div className="layout">
      <Header header={layout.header} navMenu={layout.navHeaderList} />
      <div className="stickyNavMargin"></div>
      <NotificationBar
        notification_bar={layout.header.notification_bar}
      ></NotificationBar>
      <DevTools response={jsonObj} />
      <Outlet />
      <Footer footer={layout.footer} navMenu={layout.navFooterList} />
      <BottomFooter bottomFooter={layout.bottomFooter} />
    </div>
  );
}
