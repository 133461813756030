import React from "react";
import { useLocation } from "react-router-dom";
import styles from "../styles/HeroBanner.module.css";
import Button from "./button";
import {
  HeroBanner as HeroBannerProp,
  HeroBannerStyleButton,
} from "../typescript/components";
import { generateUniqueId } from "../helper/utils";
import parse from "html-react-parser";

export default function HeroBanner({
  hero_banner: banner,
}: {
  hero_banner: HeroBannerProp;
}) {
  const {
    display_options,
    banner_title,
    banner_title_alt,
    header_tag_type,
    banner_eyebrow,
    banner_media,
    banner_description,
    banner_logo,
    button,
    anchor,
    $,
  } = banner;

  const isVideo = (filename: string) => /\.(mp4|webm|ogg)$/i.test(filename);

  const toProperCase = (str: string) =>
    str
      .replace(/-/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");

  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  // Remove locale from pathnames (assuming locale is the first part of the path)
  const filteredPathnames = pathnames.slice(1); // Remove locale from the breadcrumb

  const alignImageClass =
    display_options.align_image === "Left"
      ? styles.alignLeft
      : styles.alignRight;
  const alignTextClass =
    display_options.align_text === "Left"
      ? styles.textAlignLeft
      : styles.textAlignCenter;
  const split5050 = display_options.split_50_50 ? styles.split5050 : "";
  const increased = display_options.increased_height ? styles.increased : "";
  const roundedCorners = display_options.rounded_corners
    ? styles.roundedCorners
    : "";
  const mainHeader = header_tag_type === "H1" ? styles.mainHeader : "";
  const isShowButton =
    display_options.show_button && button.title && button.link;

  const containerStyle = {
    backgroundColor: display_options.background_color || "transparent",
  };

  const lightBackgroundClass =
    display_options.background_color === "Light"
      ? styles.lightBackground
      : display_options.background_color === "Dark"
        ? styles.darkBackground
        : display_options.background_color === "Gradient"
          ? styles.gradientBackground
          : "";

  const appendWidthParam = (url: string, width: number) => {
    try {
      const urlObj = new URL(url);
      urlObj.searchParams.set("width", width.toString());
      return urlObj.toString();
    } catch (error) {
      console.error("Invalid URL:", error);
      return url;
    }
  };

  const Tag = header_tag_type === "H1" ? "h1" : "h3";

  return (
    <div
      id={generateUniqueId(anchor || banner_title, "anchor")}
      className={`${styles.container} ${mainHeader} ${increased} ${alignImageClass} ${split5050} ${roundedCorners}`}
      style={containerStyle}
    >
      <div className={`${styles.heroSplit} ${lightBackgroundClass}`}>
        {banner_media &&
          (isVideo(banner_media.filename) ? (
            <>
              <video autoPlay muted loop className={styles.backgroundMedia}>
                <source src={banner_media.url} type="video/mp4" />
                Your browser does not support the video tag.
               </video>
              {!display_options.remove_overlay && (
                <div className={styles.videoOverlay}></div>
              )}
            </>
          ) : (
            <div className={styles.imageContainer}>
              <img
                className={styles.imageIcon}
                loading="lazy"
                {...(banner_media.$?.url as {})}
                alt={banner_media.description}
                src={
                  banner_media.url +
                  (increased ? "?crop=782,520" : "?crop=782,350")
                }
              />
              {!display_options.remove_overlay && (
                <div className={styles.imageOverlay}></div>
              )}
            </div>
          ))}

        <div className={styles.contentWrapper}>
          {filteredPathnames && display_options.breadcrumb && (
            <div className={`${alignTextClass} ${styles.breadcrumb}`}>
              <span>
                <a href="/" className={styles.breadcrumbLink}>
                  Home
                </a>
              </span>
              {filteredPathnames.map((value, index) => {
                const to = `/${filteredPathnames
                  .slice(0, index + 1)
                  .join("/")}`;
                const isLast = index === filteredPathnames.length - 1;

                return (
                  <React.Fragment key={to}>
                    <span>&nbsp;/&nbsp;</span>
                    {isLast ? (
                      <span
                        key={to}
                        className={styles.currentBreadcrumb}
                        aria-current="page"
                      >
                        {toProperCase(value)}
                      </span>
                    ) : (
                      <span key={index}>
                        <a href={to} className={styles.breadcrumbLink}>
                          {toProperCase(value)}
                        </a>
                      </span>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          )}
          <div className={`${styles.contentContainer} ${alignTextClass}`}>
            <div className={`${styles.content} ${alignTextClass}`}>
              {banner_logo && (
                <img
                  className={styles.imageLogo}
                  {...(banner_logo.$?.url as {})}
                  alt={banner_logo.description}
                  src={appendWidthParam(banner_logo.url, 688)}
                />
              )}
              <div className={styles.copy}>
                {banner_eyebrow && (
                  <div className={styles.eyebrow}>{banner_eyebrow}</div>
                )}
                <Tag className={styles.title} {...($?.banner_title as {})}>
                  {banner_title && (
                    <span className={styles.titlePart1}>{banner_title}</span>
                  )}
                  {banner_title_alt && (
                    <span className={styles.titlePart2}>
                      {banner_title_alt}
                    </span>
                  )}
                </Tag>
                {banner_description && (
                  <div className={styles.description}>
                    {parse(banner_description)}
                  </div>
                )}
              </div>
              {isShowButton && (
                <Button
                  text={button.title}
                  showIcon
                  propWidth="250px"
                  propBackgroundColor={
                    button.style === HeroBannerStyleButton.BRAND_SPECIFIC
                      ? `var(--brand-primary-500)`
                      : `var(--charcoal-500)`
                  }
                  propHoverBackgroundColor="transparent"
                  propTextColor="var(--white)"
                  link={button.link}
                  propBorderColor={
                    button.style === HeroBannerStyleButton.BRAND_SPECIFIC
                      ? `var(--brand-primary-500)`
                      : `var(--charcoal-500)`
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
