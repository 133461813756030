import React from "react";
import styles from "../styles/Footer.module.css";
import Skeleton from "react-loading-skeleton";
import { NavLink, useMatch, useResolvedPath } from "react-router-dom";
import { FooterRes, PageReference } from "../typescript/response";
import Button from "./button";
import Snipe from "./snipe";

export default function Footer({
  footer,
  navMenu,
}: {
  footer: FooterRes;
  navMenu: PageReference[];
}) {
  // Get the current locale

  const isFormTechnologiesTaxonomy =
    footer?.taxonomies &&
    footer?.taxonomies[0]?.term_uid === "form_technologies";

  // Determine the number of columns: 3 by default, 2 if "Form Technologies"
  const numColumns = isFormTechnologiesTaxonomy ? 2 : 3;
  const itemsPerColumn = Math.ceil(navMenu.length / numColumns);

  // Split the navMenu into chunks based on the calculated number of columns
  const linkSections = Array.from({ length: numColumns }, (_, i) =>
    navMenu.slice(i * itemsPerColumn, i * itemsPerColumn + itemsPerColumn)
  );

  return (
    <>
      <Snipe key="snipe" i18nIsDynamicList snipe={footer.snipe}></Snipe>
      <footer>
        <div className={styles.footer}>
          <section className={styles.container}>
            <div className={styles.rightColumn}>
              <div className={styles.logo}>
                {footer.logo ? (
                  <img
                    {...(footer.logo.$?.url as {})}
                    src={footer.logo.url}
                    loading="lazy"
                    alt={footer.logo.description ?? "Logo"}
                    title={footer.logo.title}
                    className={styles.brandmarkIcon}
                  />
                ) : (
                  <Skeleton width={100} />
                )}
                <h5 className={styles.title}>{footer.contact_title}</h5>
              </div>
              <div className={styles.body}>{footer.contact_description}</div>
              <div className={styles.input}>
                <input
                  className={styles.inputContent}
                  placeholder="Email"
                  type="text"
                />
              </div>
              <Button
                text={footer.contact_submit_text}
                showIcon
                propWidth="250px"
                propHeight="48px"
                propBackgroundColor="var(--primary-500)"
                propHoverBackgroundColor="transparent"
                propTextColor="var(--white)"
                link="/"
              />
            </div>

            <div className={styles.linksGrid}>
              {linkSections.map((section, sectionIndex) => (
                <div className={styles.links} key={sectionIndex}>
                  {section.map((navItem, index) => {
                    const resolved = useResolvedPath(
                      navItem.page_reference[0]?.url ?? "/"
                    );
                    const match = useMatch({
                      path: resolved.pathname,
                      end: true,
                    });

                    return (
                      navItem.page_reference?.[0]?.url && (
                        <div className={styles.footerLink} key={index}>
                          <NavLink
                            {...(navItem.$?.label as {})}
                            to={`${navItem.page_reference[0].url}${navItem.form_type ? `?form_type=${navItem.form_type}` : ""}`}
                            className={
                              match
                                ? `${styles.linkPrimary} active`
                                : styles.linkPrimary
                            }
                          >
                            {navItem.label}
                          </NavLink>
                        </div>
                      )
                    );
                  })}
                </div>
              ))}
            </div>

            <div
              className={`${styles.social} ${isFormTechnologiesTaxonomy ? styles.socialFormTechnologies : ""}`}
            >
              {footer.right_column_title && (
                <span className={styles.rightColumnTitle}>
                  {footer.right_column_title}
                </span>
              )}
              <div className={styles.socialIconWrapper}>
                {footer.social?.length > 0 &&
                  footer.social.map((social) => (
                    <a
                      href={social.link.href}
                      title={social.link.title}
                      key={social.link.title}
                    >
                      <img
                        {...(social.image.$?.url as {})}
                        src={social.image.url}
                        alt={social.image.description || social.link.title}
                        className={styles.socialIcon}
                      />
                    </a>
                  ))}
              </div>
            </div>
          </section>
        </div>
      </footer>
    </>
  );
}
