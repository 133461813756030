import React from "react";
import "swiper/css/navigation";
import "swiper/css/pagination";
import styles from "../styles/BlogGrid.module.css";
import { ResourceGrid as ResourceGridProp } from "../typescript/components";
import Button from "./button";
import BlogCard from "./blog-card";
export default function BlogGrid({
  resource_grid,
  taxonomies,
}: {
  resource_grid: ResourceGridProp;
  taxonomies?: [{ taxonomy_uid: string; term_uid: string }];
}) {
  return (
    <div className={styles.blogGridContainer}>
      <div className={styles.blogGridHeading}>
        <h3 className={styles.blogGridEyebrow}>{resource_grid.eyebrow}</h3>
        <h2 className={styles.blogGridTitle}>{resource_grid.title}</h2>
      </div>
      <div className={styles.blogGrid}>
        <section className={styles.cards}>
          {resource_grid.featured_resources.length > 0 &&
            resource_grid.featured_resources.slice(0, 3).map((item, index) => (
              <div key={index} className={styles.slide}>
                <BlogCard
                  blog_post={item}
                  component_type={resource_grid.component_type}
                />
              </div>
            ))}
        </section>
        {resource_grid.cta &&
          resource_grid.cta.title &&
          resource_grid.cta.url && (
            <Button
              text={resource_grid.cta.title}
              showIcon
              propWidth="250px"
              propBackgroundColor="var(--primary-500)"
              propHoverBackgroundColor="transparent"
              propTextColor="var(--white)"
              link={resource_grid.cta.url}
            />
          )}
      </div>
    </div>
  );
}
