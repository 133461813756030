import React, { useRef } from "react";
import styles from "../styles/TabContainer.module.css";
import { TabContainer as TabContainerProp } from "../typescript/components";
import ProductOverview from "./product-overview";
import ProductSpecs from "./product-specs";
import ResourceGrid from "./resource-grid";

export default function TabContainer({
  tab_container,
}: {
  tab_container: TabContainerProp;
}) {
  const productOverview = useRef(null);
  const productSpecs = useRef(null);
  const productResources = useRef(null);

  const handleScroll = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      // Calculate the position 20px above the target section
      const offsetTop = ref.current.offsetTop - 110;

      // Smooth scroll to the calculated position
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }
  };

  tab_container.additional_resources.component_type = "Blog Grid";
  tab_container.additional_resources.cta.title = "View All Resources";
  tab_container.additional_resources.cta.url = "/resources";

  return (
    <div className={styles.tabContainer}>
      <div className={styles.tabsFlexible}>
        <div className={styles.horizontalTabWrapper}>
          <div className={styles.horizontalTab}>
            <div
              onClick={() => handleScroll(productOverview)}
              className={styles.tab}
            >
              {tab_container.overview.title}
            </div>
          </div>
          <div className={styles.horizontalTab}>
            <div
              onClick={() => handleScroll(productSpecs)}
              className={styles.tab}
            >
              {tab_container.specifications.title}
            </div>
          </div>
          <div className={styles.horizontalTab}>
            <div
              onClick={() => handleScroll(productResources)}
              className={styles.tab}
            >
              {tab_container.additional_resources.title}
            </div>
          </div>
        </div>
      </div>
      <section ref={productOverview}>
        <ProductOverview
          product_overview={tab_container.overview}
        ></ProductOverview>
      </section>
      <section ref={productSpecs}>
        <ProductSpecs
          product_specs={tab_container.specifications}
        ></ProductSpecs>
      </section>
      <section ref={productResources}>
        <ResourceGrid
          resource_grid={tab_container.additional_resources}
        ></ResourceGrid>
      </section>
    </div>
  );
}
