import React, { useEffect, useRef } from "react";
import { extractWistiaIdFromHtmlSnippet } from "../sdk/utils";

export default function WistiaVideo({
  embedUrl,
  width = undefined, // Wistia's default is no width specified
  height = undefined, // Wistia's default is no width specified
  responsive = true,
  padding = "56.25% 0 0", // Wistia's aspect ratio 16/9 default
}: {
  embedUrl: string;
  width?: string;
  height?: string;
  padding?: string;
  responsive?: boolean;
}) {
  const containerRef = useRef<HTMLDivElement>(null);
  const videoId = extractWistiaIdFromHtmlSnippet(embedUrl);

  useEffect(() => {
    if (!videoId) return;

    const script1 = document.createElement("script");
    script1.src = `https://fast.wistia.com/embed/medias/${videoId}.jsonp`;
    script1.async = true;
    document.head.appendChild(script1);

    const script2 = document.createElement("script");
    script2.src = "https://fast.wistia.com/assets/external/E-v1.js";
    script2.async = true;
    document.head.appendChild(script2);

    script2.onload = () => {
      if (window.Wistia && window.Wistia.api) {
        const videoInstance = window.Wistia.api(videoId);
        if (videoInstance) {
          // When the video is ready, resize it
          videoInstance.onReady(() => {
            videoInstance.resize();
          });
        }
      }
    };

    // Resize handler to adjust video on window resize
    const handleResize = () => {
      if (window.Wistia && window.Wistia.api) {
        const videoInstance = window.Wistia.api(videoId);
        if (videoInstance) {
          videoInstance.resize();
        }
      }
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function on component unmount
    return () => {
      document.head.removeChild(script1);
      document.head.removeChild(script2);
      window.removeEventListener("resize", handleResize);
    };
  }, [videoId]);

  if (!responsive) {
    return (
      <div
        ref={containerRef}
        className={`wistia_embed wistia_async_${videoId}`}
        style={{
          height: "366px",
          width: "600px",
        }}
      >
        &nbsp;
      </div>
    );
  }

  return (
    <div
      ref={containerRef}
      className="wistia_responsive_padding"
      style={{
        padding: padding,
        position: "relative",
        width: width,
        height: height,
      }}
    >
      <div
        className="wistia_responsive_wrapper"
        style={{
          position: "absolute",
          height: "100%",
          left: 0,
          top: 0,
          width: "100%",
        }}
      >
        <div
          className={`wistia_embed wistia_async_${videoId} videoFoam=true`}
          style={{
            height: "100%",
            position: "relative",
            width: "100%",
          }}
        >
          <div
            className="wistia_swatch"
            style={{
              height: "100%",
              left: 0,
              opacity: 0,
              overflow: "hidden",
              position: "absolute",
              top: 0,
              transition: "opacity 200ms",
              width: "100%",
            }}
          >
            <img
              src={`https://fast.wistia.com/embed/medias/${videoId}/swatch`}
              style={{
                filter: "blur(5px)",
                height: "100%",
                objectFit: "contain",
                width: "100%",
              }}
              alt=""
              aria-hidden="true"
              onLoad={(e) => {
                (e.target as any).parentNode.style.opacity = 1;
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
