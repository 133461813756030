import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styles from "../styles/EventSpotlightBanner.module.css";
import Skeleton from "react-loading-skeleton";
import Button from "./button";
import { EventSpotlightBannerProps } from "../typescript/components";
import parse from "html-react-parser";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper/types";
import { Chevron } from "./icon/chevron";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import WistiaVideo from "./wistia-video";

export default function EventSpotlightBanner({
  event_spotlight_banner,
}: {
  event_spotlight_banner: EventSpotlightBannerProps;
}) {
  const slidesPerView = 1;
  const totalSlides = event_spotlight_banner?.items?.length;
  const prevRef = useRef<HTMLButtonElement | null>(null);
  const nextRef = useRef<HTMLButtonElement | null>(null);
  const swiperRef = useRef<SwiperType | null>(null);
  const paginationRef = useRef<HTMLDivElement | null>(null);
  const contentCarouselRef = useRef<HTMLDivElement | null>(null);
  const contentImgRef = useRef<HTMLDivElement | null>(null);
  const [paginationPosition, setPaginationPosition] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const shouldShowNavigation = slidesPerView < totalSlides;

  const calculatePaginationPosition = () => {
    if (swiperRef.current && contentImgRef.current) {
      const refHeight = swiperRef.current.el.clientHeight - contentImgRef.current.offsetHeight;
      const _paginationHeight = window.innerWidth <= 768 ? refHeight + 48 : refHeight - 24;
      setPaginationPosition(_paginationHeight);
    }
  };

  useLayoutEffect(() => {
    setIsMobile(window.innerWidth <= 768);
    calculatePaginationPosition();
  }, [window.innerWidth]);

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.params) {
      const navigation = swiperRef.current.params.navigation;

      if (navigation) {
        navigation.prevEl = prevRef.current;
        navigation.nextEl = nextRef.current;

        swiperRef.current.navigation?.destroy();
        swiperRef.current.navigation?.init();
        swiperRef.current.navigation?.update();
      }
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      calculatePaginationPosition();
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);


  const shadow = event_spotlight_banner.shadow ? styles.shadow : "";
  const dark_background = event_spotlight_banner.dark_background
    ? styles.darkBackground
    : styles.lightBackground;

  return (
    <div className={`${styles.wrapper} ${shadow} ${dark_background}`}>
      <div className={styles.innerWrap}>
        <div className={styles.content}>
          {event_spotlight_banner?.title ? (
            <h3 className={styles.title}>{event_spotlight_banner?.title}</h3>
          ) : (
            <Skeleton width={100} />
          )}
          {event_spotlight_banner?.description ? (
            <p className={styles.subtitle}>
              {parse(event_spotlight_banner?.description)}
            </p>
          ) : (
            <Skeleton width={100} />
          )}
          {event_spotlight_banner?.button.link.title && (
            <Button
              text={event_spotlight_banner?.button.link.title}
              showIcon
              propWidth="250px"
              propBorderColor={`${event_spotlight_banner?.button.style === "Solid" ? `var(--charcoal-500)` : `var(--brand-primary-500)`}`}
              propBackgroundColor={`${event_spotlight_banner?.button.style === "Solid" ? `var(--charcoal-500)` : `var(--brand-primary-500)`}`}
              propHoverBackgroundColor="transparent"
              propTextColor="var(--white)"
              propHoverText={`${event_spotlight_banner?.button.style === "Solid" ? `var(--charcoal-500)` : `var(--brand-primary-500)`}`}
              link={event_spotlight_banner?.button.link.url}
            />
          )}
        </div>
        <div className={styles.swiperWrap}  ref={contentCarouselRef}>
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            onSwiper={(swiper: SwiperType) => (swiperRef.current = swiper)}
            slidesPerView={slidesPerView}
            spaceBetween={0}
            className={`${styles.spotlightCarousel} spotlight-swiper`}
            loop={false}
            navigation={{
              nextEl: nextRef.current,
              prevEl: prevRef.current,
            }}
            pagination={{
              el: `.${styles.pagination}`,
              clickable: true,
              dynamicBullets: true
            }}
          >
            {event_spotlight_banner.items?.length &&
              event_spotlight_banner.items?.map((slide, index) => (
                <SwiperSlide key={index}>
                  <div className={styles.contentCarousel} style={{
                    gap: `${isMobile && shouldShowNavigation && "94px"}`
                  }}>
                    <div className={styles.contentImg} ref={contentImgRef}>
                      {!slide?.media?.url && slide?.wistia ? (
                        <WistiaVideo
                          embedUrl={slide.wistia}
                          responsive={true}
                        />
                      ) : (
                        slide?.media && (
                          <img
                            src={slide?.media?.url}
                            alt={slide?.media?.description}
                          />
                        )
                      )}
                    </div>
                    {slide?.title && <div className={styles.contentText}>
                      <h4
                        className={styles.titleCarousel}
                        dangerouslySetInnerHTML={{
                          __html: slide?.title,
                        }}
                      ></h4>
                      {slide?.description && (
                        <p
                          className={styles.descriptionCarousel}
                          dangerouslySetInnerHTML={{
                            __html: slide?.description,
                          }}
                        ></p>
                      )}
                    </div>}
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
          {shouldShowNavigation && (
            <div className={styles.navigationWrapper} style={{
              top: `-${paginationPosition}px`
            }}>
              <button ref={prevRef} className={styles.prevButton}>
                <Chevron
                  direction="left"
                  color="var(--chevron-color, var(--charcoal-500))"
                  size={20}
                />
              </button>
              <div ref={paginationRef} className={styles.pagination}></div>
              <button ref={nextRef} className={styles.nextButton}>
                <Chevron
                  direction="right"
                  color="var(--chevron-color, var(--charcoal-500))"
                  size={20}
                />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
