import React, { useRef, useEffect, useState } from "react";
import VerticalCard from "./vertical-card";
import styles from "../styles/VerticalCarousel.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper/types";
import {
  VerticalCard as VerticalCardProps,
  VerticalCarousel as VerticalCarouselProp,
} from "../typescript/components";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Button from "./button";
import { Chevron } from "./icon/chevron";
import Modal from "./modal";
import BioDetail from "./bio-detail";
import { generateUniqueId, handleScrollToHash } from "../helper/utils";
import { handleSwiperBreakpoint } from "../sdk/utils";

export default function VerticalCarousel({
  vertical_carousel,
}: {
  vertical_carousel: VerticalCarouselProp;
}) {
  const {
    vertical_cards,
    eyebrow,
    title,
    title_alt,
    subtitle,
    cta,
    display_options,
    anchor,
  } = vertical_carousel;
  const prevRef = useRef<HTMLButtonElement | null>(null);
  const nextRef = useRef<HTMLButtonElement | null>(null);
  const paginationRef = useRef<HTMLDivElement | null>(null);
  const swiperRef = useRef<SwiperType | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedModel, setSelectedModel] = useState<VerticalCardProps | null>(
    null
  );
  const openModal = (item: VerticalCardProps) => {
    setSelectedModel(item);
    setIsModalOpen(true);
  };
  const closeModal = () => setIsModalOpen(false);
  const isCardTeam = display_options.card_team_style;
  const showPopup = display_options.show_popup;
  const totalSlides = vertical_cards.length;
  const [currentSlidesPerView, setCurrentSlidesPerView] = useState<number>(5);
  const shouldShowNavigation =
    currentSlidesPerView < totalSlides &&
    totalSlides > 1 &&
    display_options.show_navigation;

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.params) {
      const navigation = swiperRef.current.params.navigation;

      if (navigation) {
        navigation.prevEl = prevRef.current;
        navigation.nextEl = nextRef.current;

        swiperRef.current.navigation?.destroy();
        swiperRef.current.navigation?.init();
        swiperRef.current.navigation?.update();
      }
    }
  }, []);

  useEffect(() => {
    handleScrollToHash(generateUniqueId(anchor || title, "vertical-carousel"));
  }, [anchor, title]);

  return (
    <div
      id={generateUniqueId(anchor || title, "vertical-carousel")}
      className={
        isCardTeam
          ? [styles.cardTeamCarouselWrapper, "ourTeamSwiperWrapper"].join(" ")
          : ""
      }
    >
      <div className={[styles.verticalCarousel].join(" ")}>
        <section className={styles.content}>
          <div className={styles.eyebrowWrapper}>{eyebrow}</div>
          <div
            className={
              isCardTeam ? styles.contentWrapper : styles.contentCardTeamWrapper
            }
          >
            <div className={styles.textContainer}>
              <div className={styles.titleWrapper}>
                <span className={styles.title}>{title}</span>
                <span className={styles.titleAlt}>{title_alt}</span>
              </div>
              {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
            </div>
            {isCardTeam && cta.cta_title && cta.cta_link && (
              <div className={styles.ourTeamCtaWrapper}>
                {cta.cta_title && (
                  <div className={styles.buttonWrapper}>
                    <Button
                      text={cta.cta_title}
                      showIcon
                      propWidth="250px"
                      propHeight="42px"
                      propBorderColor={`${
                        cta.style === "Solid"
                          ? `var(--charcoal-500)`
                          : `var(--brand-primary-500)`
                      }`}
                      propBackgroundColor={`${
                        cta.style === "Solid"
                          ? `var(--charcoal-500)`
                          : `var(--brand-primary-500)`
                      }`}
                      propTextColor="var(--white)"
                      propHoverText={`${
                        cta.style === "Solid"
                          ? `var(--charcoal-500)`
                          : `var(--brand-primary-500)`
                      }`}
                      propHoverBackgroundColor={`${
                        cta.style === "Solid" ? `transparent` : `var(--white)`
                      }`}
                      link={cta.cta_link.href}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </section>
        <section className={styles.cards}>
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            loop={true}
            onSwiper={(swiper: SwiperType) => (swiperRef.current = swiper)}
            onBreakpoint={(swiper: SwiperType) =>
              handleSwiperBreakpoint(swiper, setCurrentSlidesPerView)
            }
            navigation={{
              prevEl: prevRef.current,
              nextEl: nextRef.current,
            }}
            className={`${styles.swiper} vertical-carousel`}
            pagination={{
              el: `.${styles.pagination}`,
              clickable: true,
              enabled: shouldShowNavigation,
              dynamicBullets: true,
            }}
            centerInsufficientSlides={true}
            breakpoints={{
              320: {
                slidesPerView: 1.5,
              },
              640: {
                slidesPerView: 2.5,
              },
              1024: {
                slidesPerView: 3.5,
              },
              1200: {
                slidesPerView: 4.5,
              },
              1400: {
                slidesPerView: 5,
              },
            }}
          >
            {vertical_cards.length > 0 &&
              vertical_cards.map((item, index) => (
                <SwiperSlide
                  onClick={() => {
                    isCardTeam && openModal(item);
                  }}
                  key={index}
                  className={styles.slide}
                >
                  {item && (
                    <VerticalCard
                      vertical_card={item}
                      isCardTeamStyle={isCardTeam}
                    ></VerticalCard>
                  )}
                </SwiperSlide>
              ))}
            {showPopup && isModalOpen && selectedModel && (
              <Modal
                content={<BioDetail data={selectedModel} />}
                closeHandler={closeModal}
              />
            )}
          </Swiper>
        </section>
        <div
          className={[
            styles.navigation,
            display_options.center_cta ? styles.centeredNavigation : "",
          ].join(" ")}
        >
          {cta.cta_title && !isCardTeam && (
            <div className={styles.buttonWrapper}>
              <Button
                text={cta.cta_title}
                showIcon
                propWidth="250px"
                propBorderColor={`${
                  cta.style === "Solid"
                    ? `var(--charcoal-500)`
                    : `var(--brand-primary-500)`
                }`}
                propBackgroundColor={`${
                  cta.style === "Solid"
                    ? `var(--charcoal-500)`
                    : `var(--brand-primary-500)`
                }`}
                propTextColor="var(--white)"
                propHoverText={`${
                  cta.style === "Solid"
                    ? `var(--charcoal-500)`
                    : `var(--brand-primary-500)`
                }`}
                propHoverBackgroundColor={`${
                  cta.style === "Solid" ? `transparent` : `var(--white)`
                }`}
                link={cta.cta_title}
              />
            </div>
          )}
          {shouldShowNavigation && (
            <div
              className={`${styles.navigationWrapper} ${styles.showNavigation}`}
            >
              <button ref={prevRef} className={styles.prevButton}>
                <Chevron
                  direction="left"
                  color="var(--chevron-color, var(--charcoal-500))"
                  size={20}
                />
              </button>
              <div ref={paginationRef} className={`${styles.pagination}`}></div>
              <button ref={nextRef} className={styles.nextButton}>
                <Chevron
                  direction="right"
                  color="var(--chevron-color, var(--charcoal-500))"
                  size={20}
                />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
