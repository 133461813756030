import React from "react";
import styles from "../styles/ProductOverview.module.css";
import { ProductOverview as ProductOverviewProp } from "../typescript/components";
import GenericSmallCard from "./generic-small-card";

export default function ProductOverview({
  product_overview,
}: {
  product_overview: ProductOverviewProp;
}) {
  const cards = product_overview?.small_card?.slice(0, 4); // take up to 4 cards
  return (
    <div className={styles.root}>
      <section className={styles.content}>
        <h2 className={styles.overview}>{product_overview.title}</h2>
        <div className={styles.cardGrid}>
          {cards.map((card, index) => (
            <GenericSmallCard key={index} small_card={card} />
          ))}
        </div>
      </section>
      <section className={styles.loremIpsumDolorContainer}>
        <div
          dangerouslySetInnerHTML={{
            __html: product_overview.summary,
          }}
        />
      </section>
    </div>
  );
}
