import React, { useEffect, useRef, useState } from "react";
import styles from "../styles/SmallCardGrid.module.css";
import {
  SmallCard as SmallCardProp,
  SmallCardGrid as SmallCardGridProp,
} from "../typescript/components";
import SmallCard from "./small-card";

import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper/types";
import { Chevron } from "./icon/chevron";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { handleSwiperBreakpoint } from "../sdk/utils";
import AsideFilter from "./aside-filter";
import { TaxonomyRes } from "../typescript/pages";
import { getTaxonomyRes } from "../helper";

export default function SmallCardGrid({
  small_card_grid,
}: {
  small_card_grid: SmallCardGridProp;
}) {
  const totalSlides = small_card_grid.small_cards.length;
  const prevRef = useRef<HTMLButtonElement | null>(null);
  const nextRef = useRef<HTMLButtonElement | null>(null);
  const swiperRef = useRef<SwiperType | null>(null);
  const paginationRef = useRef<HTMLDivElement | null>(null);
  const [currentSlidesPerView, setCurrentSlidesPerView] = useState<number>(4);
  const shouldShowNavigation =
    currentSlidesPerView < totalSlides && totalSlides > 1;

    const [getCards, setCards] = useState<SmallCardProp[]>([]); // State to hold resources as an array
    const hasFilter = small_card_grid?.filter?.show_filter ? styles.hasFilter : "";

  const [filters, setFilters] = useState({
    technologyFilter: [] as TaxonomyRes[],
    serviceFilter: [] as TaxonomyRes[],
    metalFilter: [] as TaxonomyRes[],
    industryFilter: [] as TaxonomyRes[],
    finishesFilter: [] as TaxonomyRes[],
  });

  useEffect(() => {
    if (
      small_card_grid.show_carousel &&
      swiperRef.current &&
      swiperRef.current.params
    ) {
      const navigation = swiperRef.current.params.navigation;

      if (navigation) {
        navigation.prevEl = prevRef.current;
        navigation.nextEl = nextRef.current;

        swiperRef.current.navigation?.destroy();
        swiperRef.current.navigation?.init();
        swiperRef.current.navigation?.update();
      }
    }
    fetchData();

    setCards(small_card_grid.small_cards);
    setFilteredCards(small_card_grid.small_cards); // Set the combined filtered resources
  }, []);

  const parseFilters = (filters: string): string[] => {
    return filters ? filters.split(",").map((filter) => filter.trim().toLowerCase()) : [];
  };

  const [filteredCards, setFilteredCards] = useState<SmallCardProp[]>([]); // Corrected state for filtered resources

  const matchesSelectedFilters = (
    card: SmallCardProp,
    selectedFilters: {
      technology: string[];
      service: string[];
      metal: string[];
      industry: string[];
      finishes: string[];
    }
  ) => {
    return parseFilters(card.filters).some((taxonomy) => {
      if (selectedFilters.technology.includes(taxonomy)) {
        return true;
      }
      if (selectedFilters.service.includes(taxonomy)) {
        return true;
      }
      if (selectedFilters.metal.includes(taxonomy)) {
        return true;
      }
      if (selectedFilters.industry.includes(taxonomy)) {
        return true;
      }
      if (selectedFilters.finishes.includes(taxonomy)) {
        return true;
      }
      return false;
    });
  };

    async function fetchData() {
        try {
            // Assuming small_card_grid.filters.taxonomy is the array of required taxonomies
            const requiredTaxonomies = small_card_grid.filter.taxonomy;

            // A function to return a blank or default taxonomy
            const getBlankTaxonomy = (name: string) => ({
                name,
                items: [] // Or whatever default structure you want for a blank taxonomy
            });

            // Mapping of available taxonomies and their respective fetch functions
            const taxonomyMap: Record<string, () => Promise<any>> = {
                technology: () => getTaxonomyRes('technology'),
                service: () => getTaxonomyRes('service'),
                metals: () => getTaxonomyRes('metals'),
                industry: () => getTaxonomyRes('industry'),
                finishes: () => getTaxonomyRes('finishes')
            };

            // Create promises for all taxonomies, including defaults for missing ones
            const taxonomyPromises = Object.keys(taxonomyMap).map((taxonomy) => {
                if (requiredTaxonomies.includes(taxonomy)) {
                    // If the taxonomy is in the list, fetch it
                    return taxonomyMap[taxonomy]();
                } else {
                    // If the taxonomy is not in the list, return a blank/default taxonomy
                    return Promise.resolve(getBlankTaxonomy(taxonomy));
                }
            });

            // Execute all the promises in parallel
            const [technologyFilter, serviceFilter, metalFilter, industryFilter, finishesFilter] = await Promise.all(taxonomyPromises);



      setFilters({
        technologyFilter: technologyFilter || [],
        serviceFilter: serviceFilter || [],
        metalFilter: metalFilter || [],
        industryFilter: industryFilter || [],
        finishesFilter: finishesFilter || [],
      });
    } catch (error) {
      console.error(error);
    }
  }

  const applyFilters = (selectedFilters: {
    technology: string[];
    service: string[];
    metal: string[];
    industry: string[];
    finishes: string[];
  }) => {
    if (
      selectedFilters.technology?.length === 0 &&
      selectedFilters.service?.length === 0 &&
      selectedFilters.metal?.length === 0 &&
      selectedFilters.industry?.length === 0 &&
      selectedFilters.finishes?.length === 0
    ) {
      setFilteredCards(getCards); // Reset to all resources if no filters selected
    } else {
      const filtered = getCards.filter((card) => {
        return matchesSelectedFilters(card, selectedFilters);
      });

      setFilteredCards(filtered); // Update the filtered resources list
    }
  };

  const filterOptions = {
    technology: filters.technologyFilter,
    services: filters.serviceFilter,
    metals: filters.metalFilter,
    industries: filters.industryFilter,
    finishes: filters.finishesFilter,
  };

  return (
    <div className={styles.root}>
      <section className={styles.bodyParagraphCardParent}>
        {small_card_grid.eyebrow && (
          <div className={styles.eyebrow}>{small_card_grid.eyebrow}</div>
        )}
        {small_card_grid.title && (
          <div className={styles.title}>{small_card_grid.title}</div>
        )}
        {small_card_grid.description && (
          <div className={styles.spaceForAn}>{small_card_grid.description}</div>
        )}
          </section>
          
        <div className={`${styles.contentWrapper} ${hasFilter}`}>
        {small_card_grid?.filter?.show_filter && (
          <div className={styles.moduleWrapper}>
            <AsideFilter
              key="resourcesFilter"
              filter={filterOptions}
              onApplyFilters={applyFilters}
            ></AsideFilter>
          </div>
        )}
        {!small_card_grid.show_carousel ? (
          filteredCards &&
          filteredCards.length > 0 && (
            <section className={styles.smallSquareCardGroup}>
              {filteredCards.map((card, index) => (
                <SmallCard
                  key={index}
                  small_card={card}
                  remove_overlay={small_card_grid.remove_overlay || false}
                />
              ))}
            </section>
          )
        ) : (
          <div className={styles.swiperWrapper}>
            <Swiper
              wrapperClass="smallCardWrapper"
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              onSwiper={(swiper: SwiperType) => (swiperRef.current = swiper)}
              onBreakpoint={(swiper: SwiperType) =>
                handleSwiperBreakpoint(swiper, setCurrentSlidesPerView)
              }
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                640: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
                1280: {
                  slidesPerView: 4,
                },
              }}
              loop={true}
              spaceBetween={1}
              navigation={{
                prevEl: prevRef.current,
                nextEl: nextRef.current,
              }}
              pagination={{
                el: `.${styles.pagination}`,
                clickable: true,
                enabled: shouldShowNavigation,
                dynamicBullets: true,
              }}
              className={`${styles.customSwiper} small-card-grid`}
            >
              {small_card_grid.small_cards.length > 0 &&
                small_card_grid.small_cards.map((item, index) => (
                  <SwiperSlide key={index} className={styles.slide}>
                    {item && (
                      <SmallCard
                        key={index}
                        small_card={item}
                        remove_overlay={small_card_grid.remove_overlay || false}
                      />
                    )}
                  </SwiperSlide>
                ))}
              {shouldShowNavigation && (
                <div className={styles.navigationWrapper}>
                  <button ref={prevRef} className={styles.prevButton}>
                    <Chevron
                      direction="left"
                      color="var(--chevron-color, var(--charcoal-500))"
                      size={20}
                    />
                  </button>
                  <div ref={paginationRef} className={styles.pagination}></div>
                  <button ref={nextRef} className={styles.nextButton}>
                    <Chevron
                      direction="right"
                      color="var(--chevron-color, var(--charcoal-500))"
                      size={20}
                    />
                  </button>
                </div>
              )}
            </Swiper>
          </div>
        )}
      </div>
    </div>
  );
}
