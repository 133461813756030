import React from "react";
import styles from "../styles/CompanyCareer.module.css";
import { CompanyCareerProps } from "../typescript/components";

export default function CompanyCareer({
  campany_career,
}: {
  campany_career: CompanyCareerProps;
}) {
  return (
    <div className={styles.CompanyCareerContainer}>
      <img
        className={styles.logoTeam}
        loading="lazy"
        alt={campany_career.description || "Company Career Title"}
        src={campany_career.icon.url}
      />
      <div className={styles.CompanyCareerTitle}>{campany_career.title}</div>
      <div className={styles.CompanyCareerBody}>
        {campany_career.description}
      </div>
      <div className={styles.CompanyCareerLogoGroups}>
        {campany_career?.company_logo.length &&
          campany_career.company_logo.map((company, index) => (
            <div key={index} className={styles.CompanyCareerLogoGroup}>
              <img
                loading="lazy"
                alt={company.logo.description || "Company Career"}
                src={company.logo.url}
              />
              <a
                className={[
                  styles.CompanyCareerLogoGroup_Link,
                  styles[`CompanyCareerLogoGroup_Link${index + 1}`],
                ].join(" ")}
                href={company.link.href}
              >
                {company.link.title}
              </a>
            </div>
          ))}
      </div>
    </div>
  );
}
