import React, { useRef } from "react";
import styles from "../styles/Testimonial.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Testimonial as TestimonialProp } from "../typescript/components";
import { Chevron } from "./icon/chevron";

export default function Testimonial({
  testimonial,
}: {
  testimonial: TestimonialProp;
}) {
  const data = testimonial.testimonials;
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const paginationRef = useRef(null);
  const slidesPerView = 1;

  return (
    <div className={`${styles.container} testimonial`}>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y, Virtual]}
        slidesPerView={slidesPerView}
        slidesPerGroup={1}
        loop={true}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        pagination={{
          el: `.${styles.pagination}`,
          clickable: true,
          dynamicBullets: true,
        }}
      >
        {data.length > 0 &&
          data.map((item) => {
            const backgroundImage = item.background_image?.url
              ? `url("${item.background_image.url}")`
              : null;
            return (
              <SwiperSlide key={item.title} className={styles.slide}>
                <div
                  className={[
                    styles.wrapper,
                    item.background_image?.url
                      ? styles.hasBackground
                      : styles.noBackground,
                  ].join(" ")}
                  style={backgroundImage ? { backgroundImage } : {}}
                >
                  <h3 className={styles.title}>{item.title}</h3>
                  {item.sub_title ? (
                    <p className={styles.subtitle}>{item.sub_title}</p>
                  ) : (
                    ""
                  )}
                  <span className={styles.quoteSymbol}>&ldquo;</span>
                  <h4 className={styles.quote}>{item.quote}</h4>
                  {item.image && (
                    <img
                      className={styles.portrait}
                      src={item.image.url}
                      alt=""
                    />
                  )}
                  <p className={styles.name}>{item.name}</p>
                </div>
              </SwiperSlide>
            );
          })}
      </Swiper>
      {data.length > slidesPerView && (
        <div className={styles.navigation}>
          <button ref={prevRef} className={styles.prevButton}>
            <Chevron direction="left" color="white" size={20} />
          </button>
          <div className={styles.pagination} ref={paginationRef}></div>
          <button ref={nextRef} className={styles.nextButton}>
            <Chevron direction="right" color="white" size={20} />
          </button>
        </div>
      )}
    </div>
  );
}
