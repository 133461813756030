import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import axios, { AxiosResponse } from "axios";
import { Locale } from "../typescript/response";
import { getLocaleFromEnvironment } from "./entry";
import { DEFAULT_LOCALE } from "../typescript/constants";

interface LocalesResponse {
  locales: Locale[];
}

const {
  REACT_APP_CONTENTSTACK_API_KEY,
  REACT_APP_CONTENTSTACK_MANAGEMENT_TOKEN,
  REACT_APP_CONTENTSTACK_DEFAULT_LOCALE
} = process.env;

export const getLocales = async (): Promise<Locale[]> => {
  try {
    const url = "https://azure-na-api.contentstack.com/v3/locales";
    const response: AxiosResponse<LocalesResponse> = await axios.get(url, {
      headers: {
        api_key: REACT_APP_CONTENTSTACK_API_KEY,
        authorization: REACT_APP_CONTENTSTACK_MANAGEMENT_TOKEN,
        "Content-Type": "application/json",
      },
    });

    // Sort locales alphabetically based on the 'name' property
    const sortedLocales = response.data.locales.sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    return sortedLocales;
  } catch (error) {
    console.error("Error fetching locales:", error);

    // Optionally rethrow or return an empty array depending on how you want to handle errors
    return [];
  }
};

const initI18n = async () => {
  try {
    const locales = await getLocales();
    // const envLocale = await getLocaleFromEnvironment();
    const urlLocale = location.pathname.split("/")[1];
    const pathSegments = location.pathname.split("/").filter(Boolean);
    const firstSegment = pathSegments[0];
    const isValidLocale = /^[a-z]{2}-[a-z]{2}$/.test(firstSegment);
    const default_locale = isValidLocale ? urlLocale : REACT_APP_CONTENTSTACK_DEFAULT_LOCALE;

    const resources = locales.reduce(
      (acc: { [key: string]: { translation: object } }, locale: Locale) => {
        acc[locale.code] = {
          translation: {}, // Load your translations here
        };
        return acc;
      },
      {} as { [key: string]: { translation: object } }
    );

    const fallbackLng = locales.reduce(
      (acc: { [key: string]: string }, locale: Locale) => {
        acc[locale.code] = locale.fallback_locale || DEFAULT_LOCALE;
        return acc;
      },
      {} as { [key: string]: string }
    );

    await i18n.use(initReactI18next).init({
      resources,
      lng: default_locale ?? DEFAULT_LOCALE, // Default language
      fallbackLng: fallbackLng[`${default_locale}`] || DEFAULT_LOCALE,
      interpolation: {
        escapeValue: false,
      },
    });
  } catch (error) {
    console.error("Error initializing i18n:", error);
  }
};

initI18n();

export default i18n;
