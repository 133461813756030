import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getPageRes, getProductRes } from "../helper";
import { useLivePreviewCtx } from "../context/live-preview-context-provider";
import { ProductRes } from "../typescript/pages";
import { EntryProps } from "../typescript/components";
import Product from "./product";
import Page from "./index";
import Skeleton from "react-loading-skeleton";
import { useLocation } from 'react-router-dom';
import i18n from "../sdk/i18n";

interface PageSelectorProps {
    entry: ({ page }: EntryProps) => void;
}

export default function PageSelector({ entry }: PageSelectorProps) {
    const lpTs = useLivePreviewCtx();
    const { productFamily, productId } = useParams<{ productFamily?: string; productId: string }>();
    const navigate = useNavigate();

    const [getEntry, setEntry] = useState<{ product: ProductRes | null }>({ product: null });
    const [isProductPage, setIsProductPage] = useState<boolean | null>(null);
    const [error, setError] = useState(false);

    async function fetchData() {
        try {
            // Build the entry URL based on whether productFamily is present
            const entryUrl = location.pathname.startsWith(`/${i18n.language}`)
                ? location.pathname.replace(`/${i18n.language}`, "")
                : location.pathname;

            const product = await getProductRes(entryUrl);
            const page = await getPageRes(entryUrl);

            const isProductPage = product !== undefined && product !== null ? true : page !== undefined && page !== null ? false : null;

            setIsProductPage(isProductPage);

            if (isProductPage) {
                setEntry({ product });
                entry({ page: [product] });
            } else if (page) {
                entry({ page: [page] });
            } else {
                setError(true);
            }
        } catch (error) {
            console.error(error);
            setError(true);
        }
    }

    useEffect(() => {
        fetchData();
    }, [productId, lpTs, productFamily]);

    useEffect(() => {
        if (error) {
            navigate("/404");
        }
    }, [error, navigate]);

    if (isProductPage === null) {
        return <Skeleton height={400} />;
    }

    return isProductPage ? (
        <Product entry={entry} />
    ) : (
        <Page entry={entry} />
    );
}
