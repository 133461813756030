import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import RenderComponents from "../components/render-components";
import Skeleton from "react-loading-skeleton";
import { useLivePreviewCtx } from "../context/live-preview-context-provider";
import { getAboutRes } from "../helper";
import { EntryProps } from "../typescript/components";
import { Page } from "../typescript/pages";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
// Import the useLocale hook

export default function About({
  entry,
}: {
  entry: ({ page }: EntryProps) => void;
}) {
  const { i18n } = useTranslation();
  const location = useLocation();
  const lpTs = useLivePreviewCtx();
  const [getEntries, setEntries] = useState({} as Page);
  const [error, setError] = useState(false);

  const entryUrl = location.pathname.startsWith(`/${i18n.language}`)
    ? location.pathname.replace(`/${i18n.language}`, "")
    : location.pathname;

  async function fetchData() {
    try {
      const result = await getAboutRes(entryUrl);
      if (!result) setError(true);
      setEntries(result);
      entry({ page: [result] });
    } catch (error) {
      console.error("Error: ", error);
      setError(true);
    }
  }

  const enable_search_indexing = getEntries?.seo?.enable_search_indexing;
  const meta_title = getEntries?.seo?.meta_title;
  const meta_description = getEntries?.seo?.meta_description;
  const keywords = getEntries?.seo?.keywords;

  useEffect(() => {
    fetchData();
  }, [entryUrl, lpTs, error]);

  return Object.keys(getEntries).length ? (
    <>
      <Helmet>
        {meta_title && <title>{meta_title}</title>}
        {meta_description && (
          <meta name="description" content={meta_description} />
        )}
        {keywords && <meta name="keywords" content={keywords} />}
        <meta
          name="robots"
          content={
            enable_search_indexing ? "index, follow" : "noindex, nofollow"
          }
        />
      </Helmet>
      <RenderComponents
        pageComponents={getEntries?.page_components}
        contentTypeUid="page"
        entryUid={getEntries?.uid}
        locale={getEntries?.locale}
        taxonomies={getEntries?.taxonomies}
      />
    </>
  ) : (
    <Skeleton count={5} height={400} />
  );
}
