import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import RenderComponents from "../components/render-components";
import { getPageRes } from "../helper";
import Skeleton from "react-loading-skeleton";
import { useLivePreviewCtx } from "../context/live-preview-context-provider";
import { EntryProps } from "../typescript/components";
import { Page } from "../typescript/pages";
import styles from "../styles/Index.module.css";
import Helmet from "react-helmet";
import { useLocation } from 'react-router-dom';
import i18n from "../sdk/i18n";

export default function Home({
  entry,
}: {
  entry: ({ page }: EntryProps) => void;
}) {
  const lpTs = useLivePreviewCtx();
  const params = useParams();
  const [getEntries, setEntries] = useState({} as Page);
  const [error, setError] = useState(false);

    // Build the entry URL based on whether productFamily is present
    let entryUrl = location.pathname.startsWith(`/${i18n.language}`)
        ? location.pathname.replace(`/${i18n.language}`, "")
        : location.pathname;

    if (entryUrl === "") entryUrl = "/";

  async function fetchData() {
    try {
      const result = await getPageRes(entryUrl);
      if (!result) {
        setError(true);
      } else {
        setEntries(result);
        entry({ page: [result] });
      }
    } catch (error) {
      setError(true);
      console.error(error);
    }
  }
  const enable_search_indexing = getEntries?.seo?.enable_search_indexing;
  const meta_title = getEntries?.seo?.meta_title;
  const meta_description = getEntries?.seo?.meta_description;
  const keywords = getEntries?.seo?.keywords;
  const darkBackground = getEntries.dark_background
    ? styles.darkBackground
    : "";

  useEffect(() => {
    fetchData();
    //error && history("/404");
  }, [entryUrl, lpTs, error]);

  return Object.keys(getEntries).length ? (
    <>
      <Helmet>
        {meta_title && <title>{meta_title}</title>}
        {meta_description && (
          <meta name="description" content={meta_description} />
        )}
        {keywords && <meta name="keywords" content={keywords} />}
        <meta
          name="robots"
          content={
            enable_search_indexing ? "index, follow" : "noindex, nofollow"
          }
        />
      </Helmet>
      <div className={`${darkBackground}`}>
        <RenderComponents
          pageComponents={getEntries?.page_components}
          contentTypeUid="page"
          entryUid={getEntries?.uid}
          locale={getEntries?.locale}
          taxonomies={getEntries?.taxonomies}
        />
      </div>
    </>
  ) : (
    <Skeleton count={5} height={400} />
  );
}
