import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { GetCaseStudyRes } from "../helper";
import styles from "../styles/WhitePaper.module.css";
import { useLivePreviewCtx } from "../context/live-preview-context-provider";
import { CaseStudyRes } from "../typescript/pages";
import Helmet from "react-helmet";
import i18n from "../sdk/i18n";
import HeroBanner from "../components/hero-banner";
import { HeroBanner as HeroBannerProp } from "../typescript/components";
import HeroTitle from "../components/hero-title";
import {
  EntryProps,
  HeroTitle as HeroTitleProp,
} from "../typescript/components";

export default function CaseStudy({
  entry,
}: {
  entry: ({ page }: EntryProps) => void;
}) {
  const lpTs = useLivePreviewCtx();
  const { articleId } = useParams();
  const [getEntry, setEntry] = useState({
    caseStudy: {} as CaseStudyRes,
  });
  const [error, setError] = useState(false);

  async function fetchData() {
    try {
      const entryUrl = articleId
        ? `/resources/cast-study/${articleId}`
        : location.pathname.startsWith(`/${i18n.language}`)
          ? location.pathname.replace(`/${i18n.language}`, "")
          : location.pathname;

      // Fetch the case study
      const caseStudy = await GetCaseStudyRes(entryUrl);

      !caseStudy && setError(true);
      setEntry({ caseStudy });
      entry({ caseStudy: [caseStudy] });
    } catch (error) {
      console.error(error);
      setError(true);
    }
  }

  const enable_search_indexing =
    getEntry?.caseStudy?.seo?.enable_search_indexing;
  const meta_title = getEntry?.caseStudy?.seo?.meta_title;
  const meta_description = getEntry?.caseStudy?.seo?.meta_description;
  const keywords = getEntry?.caseStudy?.seo?.keywords;

  useEffect(() => {
    fetchData();
    //error && history("/404");
  }, [articleId, lpTs, error]);

  const { caseStudy } = getEntry;

  const heroBannerData = {
    banner_eyebrow: "CASE STUDY",
    banner_title: caseStudy.title,
    banner_title_alt: "",
    display_options: {
      align_image: "Right",
      align_text: "Left",
      background_color: "Dark",
      breadcrumb: true,
      show_button: false,
      split_50_50: true,
    },
    header_tag_type: "H1",
    //banner_description: "",
    //banner_logo: "",
    banner_media: caseStudy.featured_image,
  } as HeroBannerProp;

  const heroTitleData = {
    align_text: "Left",
    bottom_border: true,
    content: "",
    eyebrow: "CASE STUDY",
    header_tag_type: "H1",
    show_breadcrumb: true,
    title: caseStudy.title,
  } as HeroTitleProp;

  return (
    <>
      <Helmet>
        {meta_title && <title>{meta_title}</title>}
        {meta_description && (
          <meta name="description" content={meta_description} />
        )}
        {keywords && <meta name="keywords" content={keywords} />}
        <meta
          name="robots"
          content={
            enable_search_indexing ? "index, follow" : "noindex, nofollow"
          }
        />
      </Helmet>
      <div className={styles.outerWrapper}>
        {caseStudy.featured_image && caseStudy.featured_image.url ? (
          <HeroBanner
            key="contactPageTitle"
            hero_banner={heroBannerData}
          ></HeroBanner>
        ) : (
          <HeroTitle
            key="contactPageTitle"
            hero_title={heroTitleData}
          ></HeroTitle>
        )}
        <div className={styles.moduleWrapper}>
          <div className={styles.root}>
            <section className={styles.virtualTour}>
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: caseStudy.content,
                }}
              />
            </section>
            <div className={styles.divider} />
            <div className={styles.taxonomyWrapper}></div>
          </div>
        </div>
      </div>
    </>
  );
}
