import React, { useState, useEffect } from "react";
import styles from "../styles/ProductDisplayBanner.module.css";
import {
  ProductDisplayBanner as ProductDisplayBannerProp,
  ProductMedia,
} from "../typescript/components";
import Button from "./button";
import Modal from "./modal";
import {
  generateVideoThumbnail,
  generateWistiaThumbnail,
} from "../sdk/utils";
import Skeleton from "react-loading-skeleton";
import WistiaVideo from "./wistia-video";
import parse from "html-react-parser";

export default function ProductDisplayBanner({
  product_display_banner,
}: {
  product_display_banner: ProductDisplayBannerProp;
}) {
  const { title, description, cta, download, media } = product_display_banner;
  const mediaAssets = media || []; // Ensure media_assets is not null/undefined
  const [selectedMedia, setSelectedMedia] = useState<ProductMedia | null>(
    mediaAssets[0] || null
  );

  const [thumbnails, setThumbnails] = useState<{ [key: string]: string }>({});
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const closeModal = () => setIsModalOpen(false);

  // Generate thumbnails for video assets when component mounts
  useEffect(() => {
    const generateThumbnails = async () => {
      const newThumbnails: { [key: string]: string } = {};
      for (const media of mediaAssets) {
        try {
          if (media?.media?.content_type === "video/mp4")
            newThumbnails[media.media.url] = await generateVideoThumbnail(
              media.media.url
            );
          if (media?.wistia) {
            newThumbnails[media.wistia] = await generateWistiaThumbnail(
              media?.wistia
            );
          }
        } catch (error) {
          console.error("Error generating thumbnail:", error);
        }
      }
      setThumbnails(newThumbnails);
    };
    generateThumbnails();
  }, [mediaAssets]);

  const handleMediaClick = (media: ProductMedia) => {
    setSelectedMedia(media);
    setIsPlaying(false);
  };

  const handleVideoClick = () => {
    setIsPlaying(true);
    setIsModalOpen(true);
  };

  const isVideo = (media: ProductMedia | null) =>
    media?.media?.content_type === "video/mp4";
  const isWistia = (media: ProductMedia | null) => !!media?.wistia;

  const getModalContent = () => {
    if (!selectedMedia) return null;
    if (isVideo(selectedMedia))
      return (
        <video
          className={`${styles.largeVideoIcon}`}
          controls
          autoPlay={isPlaying}
          aria-label="Selected video"
          onClick={(e) => e.stopPropagation()}
        >
          <source src={selectedMedia?.media?.url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );

    if (isWistia(selectedMedia))
      return (
        <div className={`${styles.wistiaVideoModal}`}>
          <WistiaVideo embedUrl={selectedMedia.wistia} responsive={true} />
        </div>
      );

    return (
      <img
        className={`${styles.largeVideoIcon}`}
        loading="lazy"
        alt={selectedMedia?.media?.title || "Large media"}
        src={selectedMedia?.media?.url || "large-blank-card.png"}
        onClick={handleVideoClick}
      />
    );
  };

  const getPreviewMedia = () => {
    if (!selectedMedia) return <></>;
    if (isVideo(selectedMedia))
      return (
        <>
          <img
            className={styles.largeVideoIcon}
            src={
              thumbnails[selectedMedia?.media.url] ?? "/large-blank-card.png"
            }
            alt={selectedMedia?.media.filename ?? selectedMedia?.media.title}
          />
          <div className={styles.playButtonWrapper}>
            <img
              src="/play-button.svg"
              alt="Play"
              className={styles.playButton}
            />
          </div>
        </>
      );

    if (selectedMedia.wistia) {
      return (
        <>
          <img
            className={`${styles.largeVideoIcon}`}
            src={thumbnails[selectedMedia?.wistia] ?? "/large-blank-card.png"}
          />
          <div className={styles.playButtonWrapper}>
            <img
              src="/play-button.svg"
              alt="Play"
              className={styles.playButton}
            />
          </div>
        </>
      );
    }

    return (
      <img
        className={`${styles.largeVideoIcon}`}
        loading="lazy"
        alt={selectedMedia?.media?.title || "Large media"}
        src={selectedMedia?.media?.url || "large-blank-card.png"}
        onClick={handleVideoClick}
      />
    );
  };

  const getSmallCardImageSrc = (media: ProductMedia) => {
    if (isVideo(media)) {
      return thumbnails[media.media?.url] || "placeholder-image.png";
    }
    if (isWistia(media)) {
      return thumbnails[media.wistia] || "placeholder-image.png";
    }
    return media.media?.url || "placeholder-image.png"; // Fallback if media.url is undefined
  };

  return (
    <div className={styles.productDisplayHero}>
      <section className={styles.container}>
        <div className={styles.content}>
          <h2 className={styles.productFamily}>{title}</h2>
          <div className={styles.divider} />
                  <div className={styles.description}>{parse(description)}</div>
          {!cta.url && !download.url && (
            <div className={styles.buttonGroup}>
              {cta.url && (
                <Button
                  text={cta.title}
                  showIcon={false}
                  propWidth="250px"
                  propBackgroundColor="var(--primary-500)"
                  propHoverBackgroundColor="transparent"
                  propTextColor="var(--white)"
                  propAlignSelf="stretch"
                  link={cta.url}
                />
              )}
              {download.url && (
                <Button
                  text={download.title}
                  showIcon={false}
                  propWidth="250px"
                  propTextColor="var(--primary-500)"
                  propBackgroundColor="transparent"
                  propHoverBackgroundColor="var(--primary-500)"
                  propHoverText="var(--white)"
                  propAlignSelf="stretch"
                  link={download.url}
                />
              )}
            </div>
          )}
        </div>
        {mediaAssets.length > 0 && (
          <div className={styles.videoGrid}>
            {selectedMedia && (
              <div
                className={styles.videoWrapper}
                onClick={() => handleVideoClick()}
              >
                {getPreviewMedia()}
                {isPlaying && isModalOpen && (
                  <Modal
                    className={styles.productMediaModal}
                    content={getModalContent() as JSX.Element}
                    closeHandler={closeModal}
                  />
                )}
              </div>
            )}
            <div className={styles.smallCards}>
              {mediaAssets.slice(0, 3).map((media, index) => (
                <div
                  key={index}
                  className={
                    index === 0
                      ? styles.smallVideoIconWrapper
                      : styles.blankCardIconWrapper
                  }
                  onClick={() => handleMediaClick(media)}
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      handleMediaClick(media);
                    }
                  }}
                >
                  {thumbnails[media?.media?.url] ||
                  media?.media?.url ||
                  thumbnails[media?.wistia] ||
                  media?.wistia ? (
                    <img
                      className={
                        index === 0
                          ? styles.smallVideoIcon
                          : styles.blankCardIcon
                      }
                      loading="lazy"
                      alt={`Thumbnail ${index}`}
                      src={getSmallCardImageSrc(media)}
                    />
                  ) : (
                    <Skeleton width={100} />
                  )}
                  {(isVideo(media) || isWistia(media)) && (
                    <div className={styles.playButtonWrapper}>
                      <img
                        src="/play-button.svg"
                        alt="Play"
                        className={styles.playButton}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </section>
    </div>
  );
}
