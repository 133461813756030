import React from "react";
import { PageTitleHeader as PageTitleRes } from "../typescript/components";
import styles from "../styles/PageTitle.module.css";
import { useLocation } from "react-router-dom";

export default function PageTitle({
  page_title,
}: {
  page_title: PageTitleRes;
}) {
  const location = useLocation();
  const path = location.pathname;

  // Split the path into segments, skipping the language segment (usually the first one)
  const segments = path
    .split("/")
    .filter((segment) => segment)
    .slice(1);

  const breadcrumbs = [
    { name: "Home", path: "/" },
    ...segments.map((segment, index) => {
      const segmentPath = `/${segments.slice(0, index + 1).join("/")}`;
      return {
        name:
          segment.charAt(0).toUpperCase() + segment.slice(1).replace(/-/g, " "),
        path: segmentPath,
      };
    }),
  ];

  if (page_title && breadcrumbs.length > 0) {
    breadcrumbs[breadcrumbs.length - 1].name = page_title.title;
  }

  return (
    <>
      <div className={styles.innerWrapper}>
        {breadcrumbs && (
          <div className={styles.breadcrumb}>
            {breadcrumbs.map((breadcrumb, index) => (
              <span key={index}>
                <a
                  href={breadcrumb.path}
                  className={
                    index === breadcrumbs.length - 1
                      ? styles.currentBreadcrumb
                      : styles.breadcrumbLink
                  }
                >
                  {breadcrumb.name}
                </a>
                {index < breadcrumbs.length - 1 && (
                  <span className={styles.breadcrumbLink}> /&nbsp;</span>
                )}
              </span>
            ))}
          </div>
        )}

        <div className={styles.containerHeroTitle}>
          <h1
            className={styles.title}
            style={{ fontSize: page_title?.titleFontSize }}
          >
            {page_title?.title}
          </h1>
          {page_title?.description && (
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{
                __html: page_title?.description || "",
              }} // Ensure the content is HTML
            />
          )}
        </div>
      </div>
      {!page_title.hideBorder && <div className={styles.border}></div>}
    </>
  );
}
