import React, { useState, useEffect } from "react";
import styles from "../styles/LocationOverview.module.css";
import { getLocationOverviewRes, getTaxonomyRes } from "../helper";
import { getLocationRes } from "../helper";
import {
  LocationsOverviewPage,
  TaxonomyRes,
  LocationRes,
} from "../typescript/pages";
import Filter from "../components/filters";
import {
  LocationsOverviewEntryProps,
  PageTitleHeader,
} from "../typescript/components";
import PageTitle from "../components/page-title";
import Helmet from "react-helmet";
import SidebarModal from "../components/sidebar-modal";

export default function LocationsOverview({
  entry,
}: {
  entry: ({ locations_overview }: LocationsOverviewEntryProps) => void;
}) {
  const [getEntries, setEntries] = useState({} as LocationsOverviewPage);
  const [error, setError] = useState(false);
  const [dataFetched, setDataFetched] = useState(false); // Ensure data is only fetched once
  const [getLocations, setLocations] = useState<LocationRes[]>([]); // State to hold locations as an array
  const [getContinents, setContinents] = useState<TaxonomyRes[]>([]); // State to hold locations as an array
  const [getCountries, setCountries] = useState<TaxonomyRes[]>([]); // State to hold locations as an array

  const [filters, setFilters] = useState({
    locationsFilter: [] as TaxonomyRes[],
    metalsFilter: [] as TaxonomyRes[],
    certificationFilter: [] as TaxonomyRes[],
  });

  const [filteredLocations, setFilteredLocations] = useState<LocationRes[]>([]); // Add state for filtered certifications

  // Function to fetch page data
  async function fetchData() {
    try {
      const [
        result,
        locations,
        locationFilter,
        certificationFilter,
        metalFilter,
      ] = await Promise.all([
        getLocationOverviewRes("/locations"),
        getLocationRes(),
        getTaxonomyRes("location"),
        getTaxonomyRes("certifications"),
        getTaxonomyRes("metals"),
      ]);
      console.log("Locations Overlay");
      setFilters({
        locationsFilter: locationFilter || [],
        metalsFilter: metalFilter || [],
        certificationFilter: certificationFilter || [],
      });

      setLocations(locations.flat() || []); // Ensure certifications is set as a flat array
      setFilteredLocations(locations.flat() || []); // Set the filtered list initially to match the full list
      setContinents(locationFilter.filter((loc) => loc.depth === 1));
      setCountries(locationFilter.filter((loc) => loc.depth === 2));

      if (!result) {
        setError(true);
      } else {
        setEntries({ ...result });
        entry({ locations_overview: [result] });
      }

      setDataFetched(true); // Mark that data has been fetched
    } catch (error) {
      setError(true);
      console.error(error);
    }
  }

  // useEffect to fetch data only once after mount
  useEffect(() => {
    if (!dataFetched) {
      fetchData();
    }
  }, [dataFetched]); // Only run useEffect once when data hasn't been fetched

  const filterOptions = {
    locations: filters.locationsFilter,
    certifications: filters.certificationFilter,
    metals: filters.metalsFilter,
  };

  const matchesSelectedFilters = (
    loc: LocationRes,
    selectedFilters: {
      locations: string[];
      certifications: string[];
      metals: string[];
    }
  ) => {
    return loc.taxonomies.some((taxonomy) => {
      if (
        selectedFilters.locations.includes(taxonomy.term_uid) &&
        taxonomy.taxonomy_uid === "location"
      ) {
        return true;
      }
      if (
        selectedFilters.certifications.includes(taxonomy.term_uid) &&
        taxonomy.taxonomy_uid === "certifications"
      ) {
        return true;
      }
      if (
        selectedFilters.metals.includes(taxonomy.term_uid) &&
        taxonomy.taxonomy_uid === "metals"
      ) {
        return true;
      }
      return false;
    });
  };

  // Function to filter certifications based on selected filters
  const applyFilters = (selectedFilters: {
    locations: string[];
    certifications: string[];
    metals: string[];
  }) => {
    if (
      selectedFilters.locations.length === 0 &&
      selectedFilters.certifications.length === 0 &&
      selectedFilters.metals.length === 0
    ) {
      // No filters selected, show the entire list
      setFilteredLocations(getLocations);
    } else {
      // Filters are selected, apply the filtering logic
      const filtered = getLocations.filter((loc) => {
        return matchesSelectedFilters(loc, selectedFilters);
      });

      setFilteredLocations(filtered); // Update the filtered certifications list
    }
  };

  // Function to find the locations for a specific country
  const findLocationsForCountry = (countryUid: string) => {
    return filteredLocations.filter(
      (location) =>
        // Add safety checks for location.taxonomies before calling some()
        location.taxonomies &&
        location.taxonomies.some(
          (taxonomy) =>
            taxonomy.taxonomy_uid === "location" &&
            taxonomy.term_uid === countryUid
        )
    );
  };

  const pageTitleData = {
    title: getEntries?.header_title,
    description: getEntries?.header_description,
  } as PageTitleHeader;

  const enable_search_indexing = getEntries?.seo?.enable_search_indexing;
  const meta_title = getEntries?.seo?.meta_title;
  const meta_description = getEntries?.seo?.meta_description;
  const keywords = getEntries?.seo?.keywords;

  return (
    <>
      <Helmet>
        {meta_title && <title>{meta_title}</title>}
        {meta_description && (
          <meta name="description" content={meta_description} />
        )}
        {keywords && <meta name="keywords" content={keywords} />}
        <meta
          name="robots"
          content={
            enable_search_indexing ? "index, follow" : "noindex, nofollow"
          }
        />
      </Helmet>

      <div className={styles.outerWrapper}>
        <PageTitle
          key="contactPageTitle"
          page_title={pageTitleData}
        ></PageTitle>
        <div className={styles.moduleWrapper}>
          <SidebarModal
            content={
              <Filter
                key="locationsFilter"
                filter={filterOptions}
                onApplyFilters={applyFilters}
              />
            }
          />
          <div className={styles.frameParent}>
            <section className={styles.rectangleParent}>
              {getEntries && getEntries.map_image && (
                <img
                  className={styles.imageIcon}
                  loading="lazy"
                  alt={getEntries.map_image.filename}
                  src={getEntries.map_image.url}
                />
              )}
            </section>
            <div className={styles.resultsWrapper}>
              {getContinents.map((continent) => (
                <section key={continent.uid} className={styles.resultsGroup}>
                  <div className={styles.continent}>{continent.name}</div>
                  {getCountries
                    .filter((country) => country.parent_uid === continent.uid)
                    .map((country) => {
                      const locationsForCountry = findLocationsForCountry(
                        country.uid
                      );
                      if (locationsForCountry.length > 0) {
                        return (
                          <div key={country.uid}>
                            <div className={styles.country}>{country.name}</div>
                            {locationsForCountry.map((location) => (
                              <a
                                key={location.uid}
                                className={styles.location}
                                href={location.url}
                              >
                                {location.header_title} &gt;
                              </a>
                            ))}
                          </div>
                        );
                      }
                      return null;
                    })}
                </section>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
