import React, { useState, useEffect } from "react";
import styles from "../styles/ResourceOverview.module.css";
import {
    getArticleList,
    getCaseStudyList,
    getEventList,
    getNewsList,
    getResourcesOverviewRes,
    getTaxonomyRes,
    getVideoList,
    getWebinarList,
    getWhitePaperList,
} from "../helper";
import { TaxonomyRes, ResourcesOverviewPage, ResourcesRes } from "../typescript/pages";
import { PageTitleHeader, ResourcesOverviewEntryProps } from "../typescript/components";
import PageTitle from "../components/page-title";
import AsideFilter from "../components/aside-filter";
import BlogCard from "../components/blog-card";
import Helmet from "react-helmet";
import { ResourceGrid as ResourceGridProp } from "../typescript/components";
import ResourceGrid from "../components/resource-grid";

export default function ResourcesOverview({
    entry,
}: {
    entry: ({ resources_overview }: ResourcesOverviewEntryProps) => void;
}) {
    const [getEntries, setEntries] = useState({} as ResourcesOverviewPage);
    const [getResources, setResources] = useState<ResourcesRes[]>([]); // State to hold resources as an array
    const [error, setError] = useState(false);
    const [dataFetched, setDataFetched] = useState(false); // Ensure data is only fetched once

    const [availableFilters, setFilters] = useState({
        typeFilter: [] as TaxonomyRes[],
        technologyFilter: [] as TaxonomyRes[],
        serviceFilter: [] as TaxonomyRes[],
        metalFilter: [] as TaxonomyRes[],
        industryFilter: [] as TaxonomyRes[],
    });

    const [filteredResources, setFilteredResources] = useState<ResourcesRes[]>(
        []
    ); // Corrected state for filtered resources

    async function fetchData() {
        try {
            const [
                result,
                webinarList,
                whitePaperList,
                articleList,
                caseStudyList,
                videoList,
                newsList,
                eventList,
                technologyFilter,
                serviceFilter,
                metalFilter,
                industryFilter,
            ] = await Promise.all([
                getResourcesOverviewRes("/resources"),
                getWebinarList(),
                getWhitePaperList(),
                getArticleList(),
                getCaseStudyList(),
                getVideoList(),
                getNewsList(),
                getEventList(),
                getTaxonomyRes("technology"),
                getTaxonomyRes("service"),
                getTaxonomyRes("metals"),
                getTaxonomyRes("industry"),
            ]);

            const combinedResources = [
                ...webinarList.flat().map((item) => ({
                    ...item,
                    type: "Webinar",
                    cta: "View Webinar",
                })),
                ...whitePaperList.flat().map((item) => ({
                    ...item,
                    type: "Whitepaper",
                    cta: "View Whitepaper",
                })),
                ...articleList.flat().map((item) => ({
                    ...item,
                    type: "Article",
                    cta: "View Article",
                })),
                ...caseStudyList.flat().map((item) => ({
                    ...item,
                    type: "Case Study",
                    cta: "View Case Study",
                })),
                ...videoList.flat().map((item) => ({
                    ...item,
                    type: "Video",
                    cta: "Watch Video",
                })),
                ...newsList.flat().map((item) => ({
                    ...item,
                    type: "News",
                    cta: "View News",
                })),
                ...eventList.flat().map((item) => ({
                    ...item,
                    type: "Event",
                    cta: "View Event",
                })),
            ];

            // Create a new array for the `type` filter
            const typeFilter: TaxonomyRes[] = [
                { name: "Article", uid: "article", parent_uid: "Type", depth: 1, order: 0 },
                { name: "Case Study", uid: "case study", parent_uid: "Type", depth: 1, order: 0 },
                { name: "Event", uid: "event", parent_uid: "Type", depth: 1, order: 0 },
                { name: "News", uid: "news", parent_uid: "Type", depth: 1, order: 0 },
                { name: "Video", uid: "video", parent_uid: "Type", depth: 1, order: 0 },
                { name: "Webinar", uid: "webinar", parent_uid: "Type", depth: 1, order: 0 },
                { name: "Whitepaper", uid: "whitepaper", parent_uid: "Type", depth: 1, order: 0 }
            ];



            setResources(combinedResources || []); // Set the combined resources
            setFilteredResources(combinedResources || []); // Set the combined filtered resources

            setFilters({
                typeFilter: typeFilter || [],
                technologyFilter: technologyFilter || [],
                serviceFilter: serviceFilter || [],
                metalFilter: metalFilter || [],
                industryFilter: industryFilter || [],
            });

            // Function to check if a filter value exists within resources' taxonomies using filterKey as a string
            const checkFilterExistence = (
                resources: ResourcesRes[],
                filter: TaxonomyRes[] | undefined,
                filterKey: string
            ): TaxonomyRes[] => {
                if (!filter) return [];

                if (filterKey.toLowerCase() === "type") {
                    // Extract unique `type` values from resources
                    const uniqueTypes = new Set(resources.map(resource => resource.type.toLowerCase()).filter(Boolean));

                    // Return only those items from the filter that exist in the unique `type` values
                    return filter.filter(item => uniqueTypes.has(item.uid.toLowerCase()));
                }

                // Collect a set of unique values for the given filter key from resources
                const existingValues = new Set(
                    resources.map(resource => resource.type) // Cast to keyof ResourcesRes
                        .filter(value => value !== undefined && value !== null)
                );

                // Filter the taxonomy list to only include items present in the existing values
                return filter.filter(item => existingValues.has(item.name));
            };

            // Dynamically create a filter object based on existence in resources
            const availableFilters = {
                typeFilter: checkFilterExistence(combinedResources, typeFilter, "type"),
                technologyFilter: checkFilterExistence(combinedResources, technologyFilter, "technology"),
                serviceFilter: checkFilterExistence(combinedResources, serviceFilter, "service"),
                metalFilter: checkFilterExistence(combinedResources, metalFilter, "metal"),
                industryFilter: checkFilterExistence(combinedResources, industryFilter, "industry"),
            };

            setFilters({
                typeFilter: availableFilters.typeFilter || [],
                technologyFilter: availableFilters.technologyFilter || [],
                serviceFilter: availableFilters.serviceFilter || [],
                metalFilter: availableFilters.metalFilter || [],
                industryFilter: availableFilters.industryFilter || [],
            });




            if (!result) {
                setError(true);
            } else {
                setEntries({ ...result });
                entry({ resources_overview: [result] });
            }

            setDataFetched(true); // Mark that data has been fetched
        } catch (error) {
            setError(true);
            console.error(error);
        }
    }

    const filterOptions = {
        types: availableFilters.typeFilter,
        technology: availableFilters.technologyFilter,
        services: availableFilters.serviceFilter,
        metals: availableFilters.metalFilter,
        industries: availableFilters.industryFilter,
    };

    useEffect(() => {
        if (!dataFetched) {
            fetchData();
        }
    }, [dataFetched]); // Only run useEffect once when data hasn't been fetched

    const applyFilters = (selectedFilters: {
        type: string[];
        technology: string[];
        service: string[];
        metal: string[];
        industry: string[];
    }) => {
        if (
            selectedFilters.type.length === 0 &&
            selectedFilters.technology.length === 0 &&
            selectedFilters.service.length === 0 &&
            selectedFilters.metal.length === 0 &&
            selectedFilters.industry.length === 0
        ) {
            setFilteredResources(getResources); // Reset to all resources if no filters selected
        } else {
            const filtered = getResources.filter((resource) => {
                return matchesSelectedFilters(resource, selectedFilters);
            });

            setFilteredResources(filtered); // Update the filtered resources list
        }
    };

    const matchesSelectedFilters = (
        resource: ResourcesRes,
        selectedFilters: {
            type: string[];
            technology: string[];
            service: string[];
            metal: string[];
            industry: string[];
        }
    ) => {
        return resource.taxonomies.some((taxonomy) => {
            if (
                selectedFilters.type.includes(taxonomy.term_uid) &&
                taxonomy.taxonomy_uid === "type"
            ) {
                return true;
            }
            if (
                selectedFilters.technology.includes(taxonomy.term_uid) &&
                taxonomy.taxonomy_uid === "technology"
            ) {
                return true;
            }
            if (
                selectedFilters.service.includes(taxonomy.term_uid) &&
                taxonomy.taxonomy_uid === "service"
            ) {
                return true;
            }
            if (
                selectedFilters.metal.includes(taxonomy.term_uid) &&
                taxonomy.taxonomy_uid === "metals"
            ) {
                return true;
            }
            if (
                selectedFilters.industry.includes(taxonomy.term_uid) &&
                taxonomy.taxonomy_uid === "industry"
            ) {
                return true;
            }
            return false;
        });
    };



    const pageTitleData = {
        title: getEntries?.header_title,
        description: getEntries?.header_description,
    } as PageTitleHeader;

    const enable_search_indexing = getEntries?.seo?.enable_search_indexing;
    const meta_title = getEntries?.seo?.meta_title;
    const meta_description = getEntries?.seo?.meta_description;
    const keywords = getEntries?.seo?.keywords;

    const resource_grid = {
        title: getEntries?.title_case_study,
        description: getEntries?.description_case_study,
        featured_resources: getEntries?.featured_resources,
    } as ResourceGridProp;

    return (
        <>
            <Helmet>
                {meta_title && <title>{meta_title}</title>}
                {meta_description && (
                    <meta name="description" content={meta_description} />
                )}
                {keywords && <meta name="keywords" content={keywords} />}
                <meta
                    name="robots"
                    content={
                        enable_search_indexing ? "index, follow" : "noindex, nofollow"
                    }
                />
            </Helmet>
            <div className={styles.outerWrapper}>
                <PageTitle
                    key="contactPageTitle"
                    page_title={pageTitleData}
                ></PageTitle>
                {getEntries?.featured_resources?.length && (
                    <ResourceGrid resource_grid={resource_grid} />
                )}
                <div className={styles.resourceWrapper}>
                    {getEntries?.show_filter && (
                        <div className={styles.moduleWrapper}>
                            <AsideFilter
                                key="resourcesFilter"
                                filter={filterOptions}
                                onApplyFilters={applyFilters}
                            ></AsideFilter>
                        </div>
                    )}
                    <div className={styles.resultsWrapper}>
                        {filteredResources.length > 0 &&
                            filteredResources.map((item, index) => (
                                <div key={index} className={styles.slide}>
                                    <BlogCard
                                        blog_post={item as ResourcesRes}
                                        component_type="Resource List"
                                    />
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </>
    );
}
