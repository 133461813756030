import {
  ArticleRes,
  CaseStudyRes,
  CertificationsOverviewPage,
  CertificationsRes,
  EventRes,
  Img,
  Link,
  LocationRes,
  LocationsOverviewPage,
  NewsRes,
  Page,
  ResourcesOverviewPage,
  ResourcesRes,
  TaxonomyRes,
  VideoRes,
  WebinarRes,
  WhitePaperRes,
} from "./pages";
import { BrandName } from "../sdk/utils";

export type MegaNav = {
  $: MegaNav;
};

export enum HeroBannerStyleButton {
  SOLID = "Solid",
  BRAND_SPECIFIC = "Brand-specific",
}

export type ButtonHeroBanner = {
  link: string;
  title: string;
  style: HeroBannerStyleButton;
};

export type LocationFilter = {
  title: string;
};

export type HeroBanner = {
  display_options: {
    align_image: string;
    align_text: string;
    background_color: string;
    breadcrumb: boolean;
    show_button: boolean;
    split_50_50: boolean;
    remove_overlay?: boolean;
    increased_height?: boolean;
    rounded_corners?: boolean;
  };
  banner_title: string;
  banner_title_alt: string;
  header_tag_type: string;
  banner_eyebrow: string;
  banner_media?: Img;
  banner_logo?: Img;
  banner_description?: string;
  button: ButtonHeroBanner;
  anchor: string;
  $: HeroBanner;
};

export type CompanySpotlight = {
  companies: {
    tab_title: string;
    logo: Img;
    media: Img;
    media_position: string;
    title: string;
    description: string;
    cta: {
      url: string;
      title: string;
      style: BrandName & "solid" & "brand_specific";
    };
  }[];
};

export type AboutBanner = {
  title: string;
  title_alt: string;
  section_title: string;
  section_copy?: string;
  background?: Img;
  $: AboutBanner;
};

export type HeroTitle = {
  title: string;
  eyebrow: string;
  content: string;
  show_breadcrumb: boolean;
  align_text: string;
  header_tag_type: string;
  bottom_border?: boolean;
  $: HeroTitle;
};

export type PageTitleHeader = {
  title: string;
  titleFontSize?: number;
  description: string;
  hideBorder?: boolean;
  $: PageTitleHeader;
};

export type LocationOverview = {
  title: string;
};

export type SimpleCard = {
  title_alt: string;
  eyebrow: string;
  title: string;
  title_icon: boolean;
  title_image: Img;
  description: string;
  max_row_items: number;
  simple_cards: {
    title: string;
    description: string;
    icon: Img;
  }[];
  cta: {
    title: string;
    href?: string;
    url?: string;
  };
};

export type GenericSmallCard = {
  title: string;
  icon: Img;
  description: string;
};

export type ProductOverview = {
  title: string;
  summary: string;
  small_card: GenericSmallCard[];
};

export type LargeCardGrid = {
  large_cards: LargeCard[];
};

export type LargeCard = {
  title: string;
  description: string;
  background?: Img;
  cta: {
    cta_link: Img[];
    cta_title: string;
    contact_type?: string;
  };
};

export type SmallCardGrid = {
  title: string;
  eyebrow: string;
  description: string;
  small_cards: SmallCard[];
  show_carousel: boolean;
  filter: {
    show_filter: boolean;
    taxonomy: string[];
  };
  remove_overlay?: boolean;
};

export type SmallCard = {
  title: string;
  description: string;
  background?: Img;
  cta: {
    cta_link: Img;
    cta_title: string;
    contact_type?: string;
  };
  filters: string;
};

export type Timeline = {
  description: string;
  title_alt: string;
  title: string;
  eyebrow: string;
  timelines: {
    image: Img;
    description?: string;
    year?: string;
  }[];
};

export type ProductMedia = {
  media: Img;
  wistia: string;
};

export type ProductDisplayBanner = {
  title: string;
  description: string;
  cta: {
    title: string;
    url: string;
  };
  download: {
    title: string;
    url: string;
  };
  media: ProductMedia[];
  media_assets: Img[];
};

export type NotificationBar = {
  color: string;
  show_announcement: boolean;
  announcement_text: string;
  notification_type: string;
  cta_link: Link;
  $: {
    show_announcement: boolean;
    announcement_text: string;
    notification_type: string;
    cta_link: Link;
  };
};

export type TabContainer = {
  overview: {
    title: string;
    summary: string;
    small_card: GenericSmallCard[];
  };
  specifications: ProductSpecs;
  additional_resources: ResourceGrid;
};

export type ProductSpecs = {
  title: string;
  specification_file: Img;
};

export enum StyleMapBannerBtn {
  CLEAR = "Clear",
  BRAND_SPECIFIC = "Brand-specific",
}

export type MapBanner = {
  background?: Img;
  title: string;
  title_alt: string;
  description: string;
  buttons: {
    cta_link: Link;
    cta_title: string;
    style: StyleMapBannerBtn;
  }[];

  $: MapBanner;
};

export type Snipe = {
  title: string;
  description: string;
  cta: {
    title: string;
    url: string;
  };
};

export type ServiceTabDetail = {
  tab_title: string;
  title: string;
  media: Img;
  description: string;
  wistia: string;
};

export type ServicesTab = {
  title: string;
  tabs: ServiceTabDetail[];
};

export type Filter = {
  locations?: TaxonomyRes[];
  certifications?: TaxonomyRes[];
  metals?: TaxonomyRes[];
};

export type AsideFilter = {
  types?: TaxonomyRes[];
  technology?: TaxonomyRes[];
  services?: TaxonomyRes[];
  metals?: TaxonomyRes[];
  industries?: TaxonomyRes[];
  finishes?: TaxonomyRes[];
};

export type Slice = {
  image: Img;
  title: string;
  description: string;
};
export type CaseStudyProps = {
  background: {
    image: Img;
    linked: string;
  };
  case_study_title: string;
  case_study_description: string;
  button: {
    text: string;
    link: string;
    style: string;
  };
};

export type ResourceGrid = {
  component_type: string;
  eyebrow: string;
  display_eyebrow: boolean;
  title: string;
  anchor: string;
  description: string;
  cta: {
    title: string;
    url?: string;
    href?: string;
  };
  display_cta: boolean;
  featured_resources: ResourcesRes[];
  auto_resources: boolean;
  $: ResourceGrid;
};

export type ResourceList = {
  resources: ResourcesRes[];
  $: ResourceList;
};

export type BlogCard = {
  featured_image: Img;
  title: string;
  description: string;
  url: string;
  $: BlogCard;
};

export type Testimonial = {
  testimonials: {
    background_image: Img;
    title: string;
    sub_title: string;
    quote: string;
    image: Img;
    name: string;
  }[];
};

export type FeatureSection = {
  eyebrow: string;
  title: string;
  title_alt: string;
  cta: {
    cta_link: Img;
    cta_title: string;
  };
  image: Img;
  image_description: string;
  $: FeatureSection;
};

export type LogoBanner = {
  logos: {
    description: string;
    title: string;
    logo: Img;
  }[];
  display_options: {
    autoplay_delay: number;
    slides_per_view: number;
    slides_per_group: number;
  };
  $: LogoBanner;
};

export type RichText = {
  content: string;
  dynamic_form_url: string;
  $: RichText;
};

export type LanguageSelector = {
  title: string;
  locale: string;
  $: LanguageSelector;
};

export type VerticalCarousel = {
  eyebrow: string;
  title: string;
  title_alt: string;
  subtitle: string;
  display_options: {
    show_navigation: boolean;
    center_cta: boolean;
    card_team_style: boolean;
    show_popup: boolean;
  };
  vertical_cards: VerticalCard[];
  cta: {
    cta_link: Link;
    cta_title: string;
    style: string;
  };
  anchor: string;
  $: VerticalCarousel;
};

export type VerticalCard = {
  title: string;
  slogan: string;
  icon: Img;
  icon_hover: Img;
  background_image: Img;
  link: Link;
  description: string;
};

export type Section = {
  image: Img;
  title_h2: string;
  description: string;
  call_to_action: Link;
  image_alignment: string;
  $: Section;
};

export type Buckets = {
  icon: Img;
  title_h3: string;
  description: string;
  call_to_action: Link;
  $: Buckets;
};

export type SectionWithBuckets = {
  buckets: Buckets[];
  title_h2: string;
  description: string;
  bucket_tabular: boolean;
  $: SectionWithBuckets;
};

export type FromBlog = {
  $: FromBlog;
  title_h2: string;
  view_articles: Link;
  featured_blogs: {
    $: {
      url: string;
      uid: string;
      body: string;
      date: string;
      title: string;
      featured_image: Img;
    };
    url: string;
    uid: string;
    body: string;
    date: string;
    title: string;
    featured_image: Img;
  }[];
};

export type SectionWithCards = {
  cards: {
    title_h3: string;
    description: string;
    call_to_action: Link;
    $: {
      title_h3: string;
      description: string;
      call_to_action: Link;
    };
  }[];
};

export type OurTeam = {
  $: OurTeam;
  title_h2: string;
  description: string;
  employees: {
    $: {
      image: Img;
      name: string;
      designation: string;
    };
    image: Img;
    name: string;
    designation: string;
  }[];
};

export type SectionWithHtmlCode = {
  $: SectionWithHtmlCode;
  title: string;
  html_code: string;
  description: string;
  html_code_alignment: string;
};

export type Widget = {
  title_h2: string;
  type: string;
  $: {
    title_h2: string;
    type: string;
  };
};

export type Seo = {
  property: string;
  content: string;
  keywords: string;
};

export type EntryProps = {
  blogPost?: ResourcesRes[];
  page?: Page[];
  certifications?: CertificationsRes[];
  locations?: TaxonomyRes[];
  location?: LocationRes[];
  whitePaper?: WhitePaperRes[];
  caseStudy?: CaseStudyRes[];
  news?: NewsRes[];
  webinar?: WebinarRes[];
  video?: VideoRes[];
  article?: ArticleRes[];
  event?: EventRes[];
};

export type LocationsOverviewEntryProps = {
  locations_overview?: LocationsOverviewPage[];
};

export type CertificationsOverviewEntryProps = {
  certifications_overview?: CertificationsOverviewPage[];
};

export type ResourcesOverviewEntryProps = {
  resources_overview?: ResourcesOverviewPage[];
};

export type CSLocale = {
  code: string;
  name: string;
};

export type CTABannerProps = {
  title: string;
  subtitle: string;
  button: {
    text: string;
    link: {
      title: string;
      href: string;
    };
    style: string;
  };
  background: Img;
};

export type CTAOverlayBannerProps = {
  title: string;
  subtitle: string;
  button_1: {
    text: string;
    link: {
      title: string;
      href: string;
    };
    style: string;
  };
  button_2?: {
    text: string;
    link: {
      title: string;
      href: string;
    };
    style: string;
  };
  image: {
    media_file: Img;
  };
};

export type EventSpotlightBannerProps = {
  title: string;
  description: string;
  button: {
    link: {
      title: string;
      url: string;
    };
    style: string;
  };
  items: {
    title: string;
    description: string;
    media: Img;
    wistia: string;
  }[];
  shadow: boolean;
  dark_background: boolean;
};

export type FullWidthMedia = {
  media: Img;
  wistia: string;
};

export type CertificationsBannerProps = {
  icon: Img;
  eyebrow: string;
  title: string;
  button: {
    text: string;
    link: {
      title: string;
      href: string;
    };
    style: string;
  };
};

export type CompanyCareerProps = {
  icon: Img;
  title: string;
  description: string;
  company_logo: {
    logo: Img;
    link: Link;
  }[];
};

export type Presenter = {
  avatar?: Img;
  name: string;
  title?: string;
  company?: string;
};

export type PresentersProps = Presenter[];

export type ResuorceAsideProps = {
  component_title: string;
  articles: {
    article_subtitle: {
      text: string;
    };
    article_thumbnail: {
      media: Img;
    };
    article_title: {
      text: string;
    };
    cta_link: Link;
  }[];
};

export type TabBarProps = {
  tabs: {
    title: string;
    anchor: string;
  }[];
};

export type TabContentProps = {
  tabs: {
    tab_title: string;
    title: string;
    title_alt: string;
    description: string;
    content: string;
  }[];
};

export type Certification = {
  icon?: Img;
  certification_name: string;
  certifications_description?: string | TrustedHTML;
  note?: string;
  button?: {
    text: string;
    link: Link;
    style: string;
  };
};

export type CertificationsProps = Certification[];
