import { EntryProps, PageTitleHeader } from "../typescript/components";
import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "../styles/MetalSelector.module.css";
import { A11y, Navigation, Pagination, Scrollbar } from "swiper/modules";
import Box from "@mui/material/Box";
import PageTitle from "../components/page-title";
import { getMetalSelectorOverviewRes } from "../helper";
import { MetalSelectorOverviewPage } from "../typescript/pages";
import { Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import { CustomizedSwitch } from "../components/customized-mui/switch";
import CustomizedRatio from "../components/customized-mui/radio";
import CustomizedSlider from "../components/customized-mui/slider";
import { StyledTableCell, StyledTableRow } from "../components/customized-mui/table";
import { CustomizedTab, CustomizedTabs } from "../components/customized-mui/tab";
import CustomizedCheckbox from "../components/customized-mui/checkbox";
import Button from "../components/button";
import { Helmet } from "react-helmet";
import { useReactToPrint } from "react-to-print";

interface TabPanelProps {
    index: number;
    value: number;
}
interface SwiperRatioProps {
    name: string;
    id: string;
}

interface Filter {
    dieCastingMetal: string | null;
    region: string | null;
    minTensile: number;
    minYieldStrength: number;
    minThermalConductivity: number;
    minElectricalConductivity: number;
    minShearStrength: number;
    minElongation: number;
    sortBy: string;
    sortType: string;
}
interface TableRowData {
    "Compare Alloy": string;
    Elongation: string;
    "Tensile Strength": string;
    "Yield Strength (0.2%)": string;
    "Impact Strength": string;
    "Shear Strength": string;
    "Hardness Brinell": string;
    Process: string;
    Density: string;
    "Melting Point (Average +/- 50)": string;
    "Thermal Conductivity": string;
    "Coefficient of Thermal Expansion": string;
    "Electrical Conductivity": string;
    "Progress Physical": string;
    Aluminum: string;
    Copper: string;
    Magnesium: string;
    "Iron (max)": string;
    "Tin (max)": string;
    "Nickel (max)": string;
    Zinc: string;
    Manganese: string;
    Silicon: string;
    "Other-Metallic": string;
    "RoHS Compliant": string;
}

const TABS = {
    MECHANICAL: "Mechanical Properties",
    PHYSICAL: "Physical Properties",
    COMPOSITION: "Composition",
};
const compositionHeaders = ["Aluminum", "Copper", "Magnesium", "Iron (max)", "Tin (max)", "Nickel (max)", "Zinc", "Manganese", "Silicon", "Other-Metallic", "RoHS Compliant"];

const dataAndMeasurementUnitMapping = [
    {
        names: [
            {
                excelField: "Tensile Strength",
                tableName: "Tensile Strength",
                tab: TABS.MECHANICAL,
                index: 2,
            },
            {
                excelField: "Yield Strength (0.2%)",
                tableName: "Yield Strength (0.2%)",
                tab: TABS.MECHANICAL,
                index: 3,
            },
            {
                excelField: "Shear Strength",
                tableName: "Shear Strength",
                tab: TABS.MECHANICAL,
                index: 5,
            },
        ],
        unitImperial: "PSI x 10³",
        unitMetric: "MPa",
        sortable: true,
        icon: true,
        convert: (value: string) => (parseFloat(value) / 6.89476).toFixed(0).toString(), // MPa to PSI
    },
    {
        names: [
            {
                excelField: "Compare Alloy",
                tableName: "Alloy",
                tab: TABS.MECHANICAL,
                index: 0,
            },
        ],
        unitImperial: null,
        unitMetric: null,
        sortable: true,
        icon: false,
        convert: null,
    },
    {
        names: [
            {
                excelField: "Elongation",
                tableName: "Elongation",
                tab: TABS.MECHANICAL,
                index: 1,
            },
        ],
        unitImperial: "% in 50mm",
        unitMetric: "% in 50mm",
        sortable: true,
        icon: true,
        convert: null,
    },

    {
        names: [
            {
                excelField: "Impact Strength",
                tableName: "Impact Strength",
                tab: TABS.MECHANICAL,
                index: 4,
            },
        ],
        unitImperial: "ft lb",
        unitMetric: "J",
        sortable: true,
        icon: true,
        convert: (value: string) => (parseFloat(value) / 1.35582).toFixed(1).toString(), // J to ft·lb
    },
    {
        names: [
            {
                excelField: "Hardness Brinell",
                tableName: "Hardness",
                tab: TABS.MECHANICAL,
                index: 6,
            },
        ],
        unitImperial: "Brinell (HB)",
        unitMetric: "Brinell (HB)",
        sortable: true,
        icon: true,
        convert: null,
    },

    {
        names: [
            {
                excelField: "Process",
                tableName: "Die Casting Process",
                tab: TABS.MECHANICAL,
                index: 7,
            },
        ],
        unitImperial: null,
        unitMetric: null,
        sortable: true,
        icon: false,
        convert: null,
    },
    {
        names: [
            {
                excelField: "Compare Alloy",
                tableName: "Alloy",
                tab: TABS.PHYSICAL,
                index: 0,
            },
        ],
        unitImperial: null,
        unitMetric: null,
        sortable: true,
        icon: false,
        convert: null,
    },
    {
        names: [
            {
                excelField: "Compare Alloy",
                tableName: "%",
                tab: TABS.COMPOSITION,
                index: 0,
            },
        ],
        unitImperial: null,
        unitMetric: null,
        sortable: true,
        icon: false,
        convert: null,
    },
    {
        names: [
            {
                excelField: "Density",
                tableName: "Density",
                tab: TABS.PHYSICAL,
                index: 1,
            },
        ],
        unitImperial: "lb/in³",
        unitMetric: "g/cm³",
        sortable: true,
        icon: true,
        convert: (value: string) => (parseFloat(value) * 0.0361273).toFixed(1).toString(), // g/cm³ to lb/in³
    },
    {
        names: [
            {
                excelField: "Melting Point (Average +/- 50)",
                tableName: "Melting Point (Average +/- 50)",
                tab: TABS.PHYSICAL,
                index: 2,
            },
        ],
        unitImperial: "°F",
        unitMetric: "°C",
        sortable: true,
        icon: true,
        convert: (value: string) => ((parseFloat(value) * 9) / 5 + 32).toFixed(0).toString(), // °C to °F
    },
    {
        names: [
            {
                excelField: "Thermal Conductivity",
                tableName: "Thermal Conductivity",
                tab: TABS.PHYSICAL,
                index: 3,
            },
        ],
        unitImperial: "BTU/h∙ft∙°F",
        unitMetric: "W / m K",
        sortable: true,
        icon: true,
        convert: (value: string) => (parseFloat(value) * 0.577).toFixed(1).toString(), // °C to °F
    },
    {
        names: [
            {
                excelField: "Coefficient of Thermal Expansion",
                tableName: "Coefficient of Thermal Expansion",
                tab: TABS.PHYSICAL,
                index: 4,
            },
        ],
        unitImperial: "µin/in°F",
        unitMetric: "µm/m°K",
        sortable: true,
        icon: true,
        convert: (value: string) => (parseFloat(value) * 0.000021973).toFixed(1).toString(), // µm/m°K to µin/in°F
    },
    {
        names: [
            {
                excelField: "Electrical Conductivity",
                tableName: "Electrical Conductivity",
                tab: TABS.PHYSICAL,
                index: 5,
            },
        ],
        unitImperial: "%IACS",
        unitMetric: "%IACS",
        sortable: true,
        icon: true,
        convert: null,
    },
    {
        names: [
            {
                excelField: "Process",
                tableName: "Die Casting Process",
                tab: TABS.PHYSICAL,
                index: 6,
            },
        ],
        unitImperial: null,
        unitMetric: null,
        sortable: true,
        icon: false,
        convert: null,
    },
    ...compositionHeaders.map((composition: string, i: number) => ({
        names: [
            {
                excelField: composition,
                tableName: composition,
                tab: TABS.COMPOSITION,
                index: i + 1,
            },
        ],
        unitImperial: null,
        unitMetric: null,
        sortable: false,
        icon: false,
        convert: null,
    })),
];

const a11yProps = (index: number) => {
    return {
        id: `full-width-tab-${index}`,
    };
};

const dataAndMeasurementUnit = dataAndMeasurementUnitMapping
    .map((unit) => unit.names.map((name) => ({ ...unit, ...name })))
    .flat()
    .sort((a, b) => a.index - b.index);
const mechanicalExcelTitle = dataAndMeasurementUnit.filter((t) => t.tab === TABS.MECHANICAL).map((t) => t.excelField);
const physicalExcelTitle = dataAndMeasurementUnit.filter((t) => t.tab === TABS.PHYSICAL).map((t) => t.excelField);
const compositionExcelTitle = dataAndMeasurementUnit.filter((t) => t.tab === TABS.COMPOSITION).map((t) => t.excelField);

export default function MetalSelector({ entry }: { entry: ({ page }: EntryProps) => void }) {
    const defaultFilter = {
        dieCastingMetal: null,
        region: null,
        minTensile: 0,
        minElectricalConductivity: 0,
        minYieldStrength: 0,
        minThermalConductivity: 0,
        minShearStrength: 0,
        minElongation: 0,
        sortBy: "",
        sortType: "asc",
    };
    const [entries, setEntries] = useState({} as MetalSelectorOverviewPage);
    const [measurementUnit, setMeasurementUnit] = React.useState<"IMPERIAL" | "METRIC">("METRIC");
    const [tab, setTab] = React.useState<number>(0);
    const [rows, setRows] = useState({
        mechanical: [],
        physical: [],
        composition: [],
    });
    const [selectedRows, setSelectedRows] = useState([]);
    const [compare, setCompare] = useState(false);
    const [filterTempValue, setFilterTempValue] = useState(defaultFilter);
    const handleTempFilterChange = (newValue: number | number[], field: string) => {
        setFilterTempValue({ ...filterTempValue, [field]: newValue as number });
    };
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    const [data, setData] = useState([]);
    const [filter, setFilter] = useState<Filter>(defaultFilter);
    const [switchChecked, setSwitchChecked] = useState(false);
    const [print, setPrint] = useState(false);
    const pageTitleData = {
        title: entries?.header_title,
        description: entries?.header_description,
        hideBorder: true,
        titleFontSize: 49,
    } as PageTitleHeader;

    const tableRef = useRef<HTMLDivElement>(null);
    const handleClosePrintData = async () => {
        setPrint(false);
    };
    const reactToPrintFn = useReactToPrint({
        contentRef: tableRef,
        pageStyle: `
            body {
                -webkit-print-color-adjust: exact;
            }
        `,
        onAfterPrint: handleClosePrintData,
        onPrintError(errorLocation, error) {
            console.error("Error when printing ", error);
            handleClosePrintData();
        },
    });
    const handleRenderPrintData = async () => {
        setPrint(true);
        // make sure the print data is rendered
        await new Promise((resolve) => setTimeout(resolve, 0));
        reactToPrintFn();
    };

    const getSlider = (start: string, middle: string, end: string, title: string, max: number, field: keyof Filter) => (
        <div className={styles.sliderContainer} key={title}>
            <p className={styles.filterName}>{title}</p>
            <div className={styles.sliderWrapper}>
                <CustomizedSlider
                    value={Number(filterTempValue?.[field] ?? 0)}
                    onChangeCommitted={(_, newValue) => setFilter((prev) => ({ ...prev, [field]: Number(newValue || 0) }))} // only render table after commit change
                    onChange={(_, newValue) => handleTempFilterChange(newValue, field)}
                    name={title}
                    max={max}
                    marks={[
                        {
                            value: max / 2,
                            label: middle,
                        },
                    ]}
                />
            </div>
            <div className={styles.sliderMark}>
                <p>{start}</p>
                <p>{middle}</p>
                <p>{end}</p>
            </div>
        </div>
    );

    const ratiosDataMetals = [
        { id: "1", name: "Aluminum" },
        { id: "3", name: "Magnesium" },
        { id: "2", name: "Zinc1" },
    ];

    const ratiosDataRegion = [
        { id: "4", name: "North American" },
        { id: "5", name: "Europe" },
        { id: "6", name: "Asia" },
    ];

    const sliderData = useMemo(() => {
        const isUS = measurementUnit === "IMPERIAL";
        const mpaData = dataAndMeasurementUnitMapping.find((d) => d.unitMetric === "MPa");
        const wmkData = dataAndMeasurementUnitMapping.find((d) => d.unitMetric === "MPa");
        const mpa = isUS ? mpaData?.unitImperial : mpaData?.unitMetric;
        const wmk = isUS ? wmkData?.unitImperial : wmkData?.unitMetric;
        const tensileMax = isUS ? 60 : 400;
        const yieldMax = isUS ? 60 : 400;
        const thermalMax = isUS ? 100 : 170;
        const shearMax = isUS ? 50 : 325;
        return [
            getSlider(`0 ${mpa}`, `>0 ${mpa}`, `${Number(tensileMax)} ${mpa}`, `Tensile Strength (${mpa})`, Number(tensileMax), "minTensile"),
            getSlider(`0 ${mpa}`, `>0 ${mpa}`, `${Number(yieldMax)} ${mpa}`, `Yield Strength (0.2%) (${mpa})`, Number(yieldMax), "minYieldStrength"),
            getSlider(`0 ${wmk}`, `>0 ${wmk}`, `${Number(thermalMax)} ${wmk}`, `Thermal Conductivity (${wmk})`, Number(thermalMax), "minThermalConductivity"),
            getSlider(`0 ${mpa}`, `>0 ${mpa}`, `${Number(shearMax)} ${mpa}`, `Shear Strength (${mpa})`, Number(shearMax), "minShearStrength"),
            getSlider("0% IACS", ">0% IACS", "40% IACS", "Electrical Conductivity (% IACS)", 40, "minElectricalConductivity"),
            getSlider("0% in 50mm", ">0% in 50mm", "15 % in 50mm", "Elongation (% in 50mm)", 50, "minElongation"),
        ];
    }, [filterTempValue, measurementUnit]);
    const enable_search_indexing = entries?.seo?.enable_search_indexing;
    const meta_title = entries?.seo?.meta_title;
    const meta_description = entries?.seo?.meta_description;
    const keywords = entries?.seo?.keywords;
    const slidesPerView = 2.5;
    function filterAndSortRow(inputObject: TableRowData, tableHeader: string[]): any {
        const trimmedInputObject = Object.fromEntries(Object.entries(inputObject).map(([key, value]) => [key.trim(), value]));

        const sortedValues = tableHeader.map((header) => (Object.prototype.hasOwnProperty.call(trimmedInputObject, header.trim()) ? trimmedInputObject[header.trim()] : ""));

        return sortedValues;
    }

    const filterData = (rowsData: TableRowData[]) => {
        let data: TableRowData[] = JSON.parse(JSON.stringify(rowsData));
        if (measurementUnit === "IMPERIAL") {
            data = data.map((d: any) => {
                for (const key in d) {
                    if (d[key] && d[key] !== "-") {
                        const convert = dataAndMeasurementUnit.find((u) => u.excelField === key)?.convert;
                        if (convert) d[key] = convert(d[key]);
                    }
                }
                return d;
            });
        }
        if (filter.minTensile) data = data.filter((d) => d?.["Tensile Strength"] && parseFloat(d?.["Tensile Strength"]) >= filter.minTensile);
        if (filter.minYieldStrength) data = data.filter((d) => d?.["Yield Strength (0.2%)"] && parseFloat(d?.["Yield Strength (0.2%)"]) >= filter.minYieldStrength);
        if (filter.minThermalConductivity) data = data.filter((d) => d?.["Thermal Conductivity"] && parseFloat(d?.["Thermal Conductivity"]) >= filter.minThermalConductivity);
        if (filter.minShearStrength) data = data.filter((d) => d?.["Shear Strength"] && parseFloat(d?.["Shear Strength"]) >= filter.minShearStrength);
        if (filter.minElectricalConductivity)
            data = data.filter((d) => d?.["Electrical Conductivity"] && parseFloat(d?.["Electrical Conductivity"]) >= filter.minElectricalConductivity);
        if (filter.minElongation) data = data.filter((d) => d?.["Elongation"] && parseFloat(d?.["Elongation"]) >= filter.minElongation);
        if (filter.sortBy) {
            data = data.sort((a: any, b: any) => {
                const valueA = a?.[filter.sortBy];
                const valueB = b?.[filter.sortBy];

                if (typeof valueA === "string" || typeof valueB === "string") {
                    return filter.sortType === "asc" ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
                } else {
                    return filter.sortType === "asc" ? valueA - valueB : valueB - valueA;
                }
            });
        }

        const mechanicalRows = data.map((d: TableRowData) => filterAndSortRow(d, mechanicalExcelTitle)) as never[];
        const physicalRows = data.map((d: TableRowData) => filterAndSortRow(d, physicalExcelTitle)) as never[];
        const compositionRows = data.map((d: TableRowData) => filterAndSortRow(d, compositionExcelTitle)) as never[];
        setRows({
            mechanical: mechanicalRows,
            physical: physicalRows,
            composition: compositionRows,
        });
    };

    useEffect(() => {
        filterData(data);
    }, [filter, measurementUnit]);

    async function fetchData() {
        try {
            const [result] = await Promise.all([getMetalSelectorOverviewRes("/metals/metal-selector-tool")]);
            setEntries({ ...result });
            const response = await fetch(result.data.url);
            const csvText = await response.text();
            const rows = csvText.split("\n");
            const headers = rows[0].split(",") as (keyof TableRowData)[];
            const parsedData: any = rows
                .slice(1)
                .map((row) => {
                    const values = row.split(",");
                    return headers.reduce((obj, header, index: number) => {
                        obj[header] = values[index] ? values[index].trim() : "";
                        return obj;
                    }, {} as TableRowData);
                })
                .filter((row) => {
                    return Object.values(row).some((value) => value !== "");
                });
            setData(parsedData);
            filterData(parsedData);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const activeSwitchText = {
        color: "var(--charcoal-500)",
        fontWeight: 700,
    };
    const inactiveSwitchText = {
        color: "#bfbebe",
        fontWeight: 400,
    };

    const getSwiperRatio = (options: SwiperRatioProps[], field: keyof Filter, title: string, alt: string) => (
        <div className={styles.radioContainer}>
            <div className={styles.radioTitleWrapper}>
                <p className={styles.filterName}>{title}</p>
                <p className={styles.filterAlt}>{alt}</p>
            </div>
            <Swiper
                spaceBetween={0}
                className={`${styles.ratioSwiper} metal-selector-swiper`}
                slidesPerView={slidesPerView}
                loop={false}
                modules={[Navigation, Pagination, Scrollbar, A11y]}
            >
                {options?.map((slide, index) => (
                    <SwiperSlide key={index} className={styles.slide}>
                        <div className={styles.radioWrapper}>
                            <CustomizedRatio
                                value={slide.name}
                                name={slide.name}
                                checked={filter[field] === slide.name}
                                onChange={(e) => {
                                    setFilter({ ...filter, [field]: e.target.value });
                                }}
                            />
                            <p className={styles.radioTitle}>{slide.name}</p>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );

    const tableData = (rows: (string | number | { name: string; icon: boolean | undefined; excelField?: string })[][], fullHeight = false, currentTab = tab) => {
        return (
            <div className={styles.table}>
                <Box sx={{ overflow: "auto", paddingRight: "1px" }}>
                    <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                        <TableContainer sx={{ maxHeight: fullHeight ? "auto" : 615 }}>
                            <Table stickyHeader>
                                <TableHead
                                    key={1}
                                    sx={{
                                        position: "sticky",
                                        top: 0,
                                        zIndex: 1,
                                    }}
                                >
                                    <TableRow>
                                        {currentTab !== 2 && <StyledTableCell></StyledTableCell>}
                                        {rows[0].map((header: any, i) => (
                                            <>
                                                <StyledTableCell
                                                    sx={{ cursor: "pointer" }}
                                                    isCheckbox={currentTab !== 2}
                                                    key={`${header}${i}`}
                                                    onClick={() => {
                                                        setFilter({ ...filter, sortBy: header.excelField, sortType: filter.sortType === "asc" ? "desc" : "asc" });
                                                    }}
                                                >
                                                    {header.name}
                                                    {header?.icon && <img className={styles.sortIcon} loading="lazy" src="/sort.svg" />}
                                                </StyledTableCell>
                                            </>
                                        ))}
                                    </TableRow>
                                    {currentTab !== 2 && (
                                        <TableRow>
                                            <StyledTableCell isCheckbox={true} variant="footer" style={{ top: "20px" }}></StyledTableCell>
                                            {rows[1].map((item, i) => (
                                                <StyledTableCell variant="footer" key={`${item}${i}`}>
                                                    <div dangerouslySetInnerHTML={{ __html: String(item) }}></div>
                                                </StyledTableCell>
                                            ))}
                                        </TableRow>
                                    )}
                                </TableHead>
                                <TableBody>
                                    {rows.slice(currentTab === 2 ? 1 : 2).map((row, i) => (
                                        <StyledTableRow key={`${row}${i}`}>
                                            {currentTab !== 2 && (
                                                <StyledTableCell component="th" scope="row" isCheckbox>
                                                    <CustomizedCheckbox
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            const newValue = event.target.checked;
                                                            if (newValue) {
                                                                setSelectedRows((prev) => [...prev, row as never]);
                                                            } else {
                                                                setSelectedRows((prev) => prev.filter((p) => JSON.stringify(p) !== JSON.stringify(row)));
                                                            }
                                                        }}
                                                        checked={selectedRows.some((r) => JSON.stringify(r) === JSON.stringify(row))}
                                                    />
                                                </StyledTableCell>
                                            )}
                                            {row.map((item, i) => (
                                                <StyledTableCell isCheckbox={currentTab !== 2} key={`${item}${i}`} component="th" scope="row">
                                                    {String(item)}
                                                </StyledTableCell>
                                            ))}
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </div>
        );
    };

    const getTableHeaderName = (data: string[], tabs: string) => {
        return data.map((t) => {
            const unit = dataAndMeasurementUnit.find((excel) => t === excel.excelField && excel.tab === tabs);
            return {
                name: unit?.tableName || t,
                icon: unit?.icon,
                excelField: unit?.excelField,
            };
        });
    };

    const getMeasurementRow = (data: string[], tabs: string) => {
        return data.map((t) => {
            const unit = dataAndMeasurementUnit.find((excel) => t === excel.excelField && excel.tab === tabs);
            if (!unit || (!unit.unitImperial && !unit.unitMetric)) return "";
            return measurementUnit === "METRIC" ? unit.unitMetric : unit.unitImperial;
        });
    };

    const getTable = (tabIndex: number, fullHeight = false) => {
        if (tabIndex === 0) {
            const title = getTableHeaderName(mechanicalExcelTitle, TABS.MECHANICAL);
            const measurementRow = getMeasurementRow(mechanicalExcelTitle, TABS.MECHANICAL);
            const compareData = selectedRows.filter((r) => rows.mechanical.some((row) => JSON.stringify(r) == JSON.stringify(row)));
            return tableData([title, measurementRow, ...(compare ? compareData : rows.mechanical)], fullHeight, tabIndex);
        }
        if (tabIndex === 1) {
            const title = getTableHeaderName(physicalExcelTitle, TABS.PHYSICAL);
            const measurementRow = getMeasurementRow(physicalExcelTitle, TABS.PHYSICAL);
            const compareData = selectedRows.filter((r) => rows.physical.some((row) => JSON.stringify(r) == JSON.stringify(row)));
            return tableData([title, measurementRow, ...(compare ? compareData : rows.physical)], fullHeight, tabIndex);
        }
        const title = getTableHeaderName(compositionExcelTitle, TABS.COMPOSITION);
        return tableData([title, ...rows.composition], fullHeight, tabIndex);
    };

    const getPrintTable = () => {
        if (!rows.mechanical && !rows.physical && !rows.composition) return;
        return (
            <div ref={tableRef}>
                {getTable(0, true)}
                {getTable(1, true)}
                {getTable(2, true)}
            </div>
        );
    };

    function TabPanel(props: TabPanelProps) {
        const { value, index, ...other } = props;
        return (
            <div role="tabpanel" hidden={value !== index} {...other}>
                {value === index && getTable(index)}
            </div>
        );
    }

    const getTabs = useMemo(
        () => (
            <div className={styles.tableContainer}>
                <Box sx={{ width: "100%" }}>
                    <CustomizedTabs value={tab} onChange={handleChange} variant="fullWidth">
                        <CustomizedTab label="Mechanical Properties" {...a11yProps(0)} />
                        <CustomizedTab label="Physical Properties" {...a11yProps(1)} />
                        <CustomizedTab label="Composition" {...a11yProps(2)} />
                    </CustomizedTabs>
                    <TabPanel value={tab} index={0}></TabPanel>
                    <TabPanel value={tab} index={1}></TabPanel>
                    <TabPanel value={tab} index={2}></TabPanel>
                </Box>
                <div className={styles.tableFooter}>
                    <p className={styles.note}>** For comparison only.</p>
                    <div className={styles.footerButtons}>
                        <Button
                            text={"Compare Results"}
                            propWidth="147px"
                            propHeight="27px"
                            showIcon={false}
                            propBorderColor={`var(--brand-primary-500)`}
                            propBackgroundColor={`var(--white)`}
                            propHoverBackgroundColor={`var(--brand-primary-500)`}
                            propTextColor="var(--brand-primary-500)"
                            propHoverText={`var(--white)`}
                            propFontSize="10px"
                            onClick={() => {
                                if (tab !== 2) {
                                    setCompare(true);
                                }
                            }}
                        />
                        <div className={styles.buttonRight}>
                            <Button
                                text={"Print Results"}
                                propWidth="147px"
                                propHeight="27px"
                                showIcon={false}
                                propBorderColor={`var(--brand-primary-500)`}
                                propBackgroundColor={`var(--white)`}
                                propHoverBackgroundColor={`var(--brand-primary-500)`}
                                propTextColor="var(--brand-primary-500)"
                                propHoverText={`var(--white)`}
                                propFontSize="10px"
                                onClick={handleRenderPrintData}
                            />
                            <Button
                                text={"Contact an Engineer >"}
                                propWidth="147px"
                                propHeight="27px"
                                showIcon={false}
                                propBorderColor={`var(--brand-primary-500)`}
                                propBackgroundColor={`var(--brand-primary-500)`}
                                propHoverBackgroundColor="transparent"
                                propTextColor="var(--white)"
                                propHoverText={`var(--brand-primary-500)`}
                                propFontSize="10px"
                                onClick={() => null}
                            />
                        </div>
                    </div>
                </div>
            </div>
        ),
        [tab, rows, selectedRows, compare]
    );

    return (
        <>
            <Helmet>
                {meta_title && <title>{meta_title}</title>}
                {meta_description && <meta name="description" content={meta_description} />}
                {keywords && <meta name="keywords" content={keywords} />}
                <meta name="robots" content={enable_search_indexing ? "index, follow" : "noindex, nofollow"} />
            </Helmet>
            <div className={styles.container}>
                <PageTitle key="contactPageTitle" page_title={pageTitleData}></PageTitle>
                <div className={styles.main}>
                    <div className={styles.sidebarContainer}>
                        <div className={styles.switchWrapper}>
                            <p className={styles.switchTitle} style={switchChecked ? inactiveSwitchText : activeSwitchText}>
                                Imperial US/UK
                            </p>
                            <CustomizedSwitch
                                checked={measurementUnit === "METRIC"}
                                onChange={(e) => {
                                    setMeasurementUnit((prev) => (prev === "IMPERIAL" ? "METRIC" : "IMPERIAL"));
                                }}
                            />
                            <p className={styles.switchTitle} style={switchChecked ? activeSwitchText : inactiveSwitchText}>
                                Metric
                            </p>
                        </div>

                        <div className={styles.filterTitleWrapper}>
                            <p className={styles.filterTitle}>FILTERS</p>

                            <p
                                onClick={() => {
                                    setFilterTempValue(defaultFilter);
                                    setCompare(false);
                                    setSelectedRows([]);
                                    setFilter(defaultFilter);
                                }}
                                className={styles.clearFilter}
                            >
                                Clear All Filters
                            </p>
                        </div>
                        <div className={styles.filterWrapper}>
                            {getSwiperRatio(ratiosDataMetals, "dieCastingMetal", "By Die Casting Metals", "Select all that apply.")}
                            {getSwiperRatio(ratiosDataRegion, "region", "By Region", "Select all that apply.")}
                            {sliderData.map((slider) => slider)}
                        </div>
                    </div>
                    {getTabs}
                </div>
            </div>
            <div className={styles.displayNone}>{print && getPrintTable()}</div>
        </>
    );
}