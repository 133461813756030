import React from "react";
import styles from "../styles/BlogCard.module.css";
import { ResourcesRes } from "../typescript/pages";
export default function BlogCard({
  blog_post,
  component_type,
}: {
  blog_post: ResourcesRes;
  component_type: string;
}) {
  const appendWidthParam = (url: string, width: number) => {
    try {
      const urlObj = new URL(url);
      urlObj.searchParams.set("width", width.toString());
      return urlObj.toString();
    } catch (error) {
      console.error("Invalid URL:", error);
      return url;
    }
  };

  const getBlogCardClass = (component_type: string) => {
    switch (component_type) {
      case "Resource Grid":
        return styles.resourceCarousel;
      case "Resource List":
        return styles.resourceList;
      case "Blog Grid":
        return styles.blogGrid;
      default:
        return styles.resourceCarousel;
    }
  };

  const splitDescription = (description: string) => {
    if (!description || description.length === 0) return "";

    const words = description.split(" ");
    const truncated = words
      .slice(0, 12)
      .join(" ")
      ?.replace(/<[^>]+>/g, "");

    // Add ellipses if the description has more than 10 words
    return words.length > 10 ? `${truncated}...` : truncated;
  };

  const getTitle = (blog_post_item: ResourcesRes) => {
    switch (component_type) {
      case "Resource Grid":
        return blog_post_item.title;
      case "Resource List":
      case "Blog Grid":
        switch (
          blog_post_item._content_type_uid ||
          blog_post_item.type?.toLowerCase()
        ) {
          case "webinar":
            return "Webinar";
          case "whitepaper":
            return "Whitepaper";
          case "article":
            return "Article";
          case "case_study":
            return "Case Study";
          case "video":
            return "Video";
          case "news":
            return "News";
          case "event":
            return "Event";
          default:
            return blog_post_item.title; // Fallback to the post's title
        }
      default:
        return blog_post_item.title;
    }
  };

  const getDescription = (blog_post_item: ResourcesRes) => {
    switch (component_type) {
      case "Resource Grid":
      case "Blog Grid":
        return splitDescription(blog_post_item.summary);
      case "Resource List":
        return blog_post_item.title;
      default:
        return blog_post_item.title;
    }
  };

  const getLinkText = (blog_post_item: ResourcesRes) => {
    switch (
      blog_post_item._content_type_uid ||
      blog_post_item?.type?.toLowerCase()
    ) {
      case "webinar":
        return "View Webinar >";
      case "whitepaper":
        return "View Whitepaper >";
      case "article":
        return "View Article >";
      case "case_study":
        return "View Case Study >";
      case "video":
        return "Watch Video >";
      case "news":
        return "View News >";
      case "event":
        return "View Event >";
      default:
        return "Learn More >"; // Fallback to the post's title
    }
  };

  // Determine the featured image
  const featuredImage =
    blog_post._content_type_uid === "page" &&
    blog_post.page_components &&
    blog_post.page_components.length > 0
      ? blog_post.page_components[0]?.hero_banner?.banner_media
      : blog_post.featured_image;

  return (
    <div className={`${styles.blogCard} ${getBlogCardClass(component_type)}`}>
      {featuredImage ? (
        <img
          className={styles.imageIcon}
          loading="lazy"
          alt={featuredImage?.description || ""}
          src={
            featuredImage?.url ? appendWidthParam(featuredImage.url, 295) : ""
          }
        />
      ) : (
        //Needs to be rebuilt dynamically per brand.
        <img
          className={styles.imageIcon}
          loading="lazy"
          alt={"Default Resource Image"}
          src={
            "https://azure-na-images.contentstack.com/v3/assets/bltc3d459c709c6e7a5/bltbe37e282ea60ffda/671b192df9470596c8b73a1f/Image_(2).png"
          }
        />
      )}

      <div className={styles.content}>
        <div className={styles.copy}>
          <span className={styles.title}>{getTitle(blog_post)}</span>
          <p className={styles.description}>{getDescription(blog_post)}</p>
        </div>
        <a className={styles.link} href={blog_post.url}>
          {getLinkText(blog_post)}
        </a>
      </div>
    </div>
  );
}
