import React from "react";
import Button from "./button";
import styles from "../styles/MapBanner.module.css";
import {
  MapBanner as MapBannerProp,
  StyleMapBannerBtn,
} from "../typescript/components";

export default function MapBanner({
  map_banner,
}: {
  map_banner: MapBannerProp;
}) {
  const isVideo = (filename: string) => {
    return /\.(mp4|webm|ogg)$/i.test(filename);
  };

  const getColorBrandSpecific = (style: StyleMapBannerBtn) => {
    return style === StyleMapBannerBtn.BRAND_SPECIFIC
      ? `var(--brand-primary-500)`
      : `var(--charcoal-500)`;
  };

  // Split the buttons into two columns
  const half = Math.ceil(map_banner.buttons.length / 2);
  const firstColumnButtons = map_banner.buttons.slice(0, half);
  const secondColumnButtons = map_banner.buttons.slice(half);

  return (
    <div className={styles.mapBanner}>
      <div className={styles.video}>
        {map_banner.background && isVideo(map_banner.background.filename) ? (
          <video autoPlay muted loop className={styles.backgroundMedia}>
            <source src={map_banner.background.url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img
            src={map_banner.background?.url}
            alt={map_banner.background?.title}
            className={styles.backgroundMedia}
          />
        )}
      </div>
      <div className={styles.leftColumn}>
        <div className={styles.content}>
          <div className={styles.title}>
            <h2 className={styles.title1}>{map_banner.title}</h2>
            {map_banner.title_alt && <h2 className={styles.title2}>{map_banner.title_alt}</h2>}
          </div>
          <div className={styles.whereverYouAre}>{map_banner.description}</div>
        </div>
        <div className={styles.buttonsContainer}>
          <div className={styles.buttons}>
            {firstColumnButtons.map((button, index) => (
              <Button
                key={index}
                text={button.cta_title}
                propTextColor={getColorBrandSpecific(button.style)}
                propBorderColor={getColorBrandSpecific(button.style)}
                propHoverBackgroundColor={getColorBrandSpecific(button.style)}
                propHoverText="white"
                showIcon
                propWidth="300px"
                link={button.cta_link.href}
              />
            ))}
          </div>
          <div className={styles.buttons}>
            {secondColumnButtons.map((button, index) => (
              <Button
                key={index}
                text={button.cta_title}
                propTextColor={getColorBrandSpecific(button.style)}
                propBorderColor={getColorBrandSpecific(button.style)}
                propHoverBackgroundColor={getColorBrandSpecific(button.style)}
                propHoverText="white"
                showIcon
                propWidth="300px"
                link={button.cta_link.href}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
