import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getLocaleFromEnvironment } from "../sdk/entry";
import { isValidLocale } from "../sdk/utils";
import { DEFAULT_LOCALE } from "../typescript/constants";

const {
  REACT_APP_CONTENTSTACK_DEFAULT_LOCALE
} = process.env;

export const useLocaleRedirect = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const default_locale = REACT_APP_CONTENTSTACK_DEFAULT_LOCALE ?? DEFAULT_LOCALE;
  // const [defaultLocale, setDefaultLocale] = useState<string | undefined>(undefined);

  // useEffect(() => {
  //   const fetchDefaultLocale = async () => {
  //     try {
  //       const locale = await getLocaleFromEnvironment();
  //       setDefaultLocale(locale);
  //     } catch (error) {
  //       console.error("Failed to fetch default locale:", error);
  //     }
  //   };

  //   fetchDefaultLocale();
  // }, []);

  useEffect(() => {
    // if (!defaultLocale) return;

    if (!isValidLocale(location.pathname)) {
      // Redirect to the URL with the default locale
      const newPath = `/${default_locale}${location.pathname}`;
      navigate(newPath, { replace: true });
    }
  }, [location, navigate]);
};
