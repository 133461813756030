export const handleScrollToHash = (hash: string, offsetHeight?: number) => {
  const currentHash = window.location.hash;
  if (currentHash.substring(1) === hash) {
    const element = document.getElementById(hash);
    if (element) {
      const navbarOffset = offsetHeight || 96; // default offset is navbar height
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - navbarOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }
};

export const generateUniqueId = (
  title: string | undefined,
  prefix?: string
): string => {
  const normalizedTitle = title
    ? title
        .toLowerCase()
        .trim()
        .replace(/\s+/g, "-")
        .replace(/[^\w-]+/g, "")
    : "";
  return `${prefix ? prefix + "-" : ""}${normalizedTitle}`.replace(
    /^-|-$/g,
    ""
  );
};

export const getRandomItems = <T>(array: T[], numItems: number): T[] => {
  // Create a shallow copy of the array and shuffle it
  const shuffled = array.slice().sort(() => 0.5 - Math.random());
  // Return the specified number of items
  return shuffled.slice(0, numItems);
};
