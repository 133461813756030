import {
  getEntry,
  getEntryByUrl,
  getLocations,
  getTaxonomy,
} from "../sdk/entry";
import { addEditableTags } from "@contentstack/utils";
import { BottomFooterRes, FooterRes, HeaderRes } from "../typescript/response";
import {
  Page,
  ProductRes,
  ArticleRes,
  EventRes,
  TaxonomyRes,
  LocationRes,
  LocationsOverviewPage,
  CertificationsRes,
  CertificationsOverviewPage,
  ContactPage,
  ResourcesRes,
  ResourcesOverviewPage,
  WhitePaperRes,
  MetalSelectorOverviewPage,
  CaseStudyRes,
} from "../typescript/pages";

const liveEdit = process.env.REACT_APP_CONTENTSTACK_LIVE_EDIT_TAGS === "true";

export const getHeaderRes = async (): Promise<HeaderRes> => {
  const response = (await getEntry({
    contentTypeUid: "header",
    referenceFieldPath: [
      "navigation_menu.page_reference",
      "navigation_menu.sub_navigation.page_reference",
      "locations.page_reference",
      "search_page",
      "request_quote.page_reference",
      "contact_us.page_reference",
    ],
    jsonRtePath: ["notification_bar.announcement_text"],
  })) as HeaderRes[][];

  const headerRes = response[0][0];

  if (liveEdit) {
    addEditableTags(headerRes, "header", true);
  }

  return headerRes;
};

export const getFooterRes = async (): Promise<FooterRes> => {
  const response = (await getEntry({
    contentTypeUid: "footer",
    referenceFieldPath: ["navigation_menu.page_reference"],
    jsonRtePath: ["copyright"],
  })) as FooterRes[][];

  if (liveEdit) {
    addEditableTags(response[0][0], "footer", true);
  }

  return response[0][0];
};

export const getBottomFooterRes = async (): Promise<BottomFooterRes> => {
  const response = (await getEntry({
    contentTypeUid: "bottom_footer",
    jsonRtePath: ["copyright"],
    referenceFieldPath: undefined,
  })) as BottomFooterRes[][];
  if (liveEdit) {
    addEditableTags(response[0][0], "bottom_footer", true);
  }
  return response[0][0];
};

export const getAllEntries = async (): Promise<Page[]> => {
  const response = (await getEntry({
    contentTypeUid: "page",
    jsonRtePath: undefined,
    referenceFieldPath: undefined,
  })) as Page[][];
  if (liveEdit) {
    response[0].forEach((entry) => addEditableTags(entry, "page", true));
  }
  return response[0];
};

export const getPageRes = async (entryUrl: string): Promise<Page> => {
  const response = (await getEntryByUrl({
    contentTypeUid: "page",
    entryUrl,
    referenceFieldPath: [
      "page_components.blog_banner.featured_blogs",
      "page_components.resource_grid.featured_resources",
      "page_components.large_card_grid.large_cards.cta.cta_link",
    ],
    jsonRtePath: [
      "page_components.from_blog.featured_blogs.body",
      "page_components.resource_grid.featured_resources.resource",
      "page_components.large_card_grid.large_cards.cta.cta_link",
    ],
  })) as Page[];
  if (liveEdit) {
    addEditableTags(response[0], "page", true);
  }

  return response[0];
};

export const getContactRes = async (entryUrl: string): Promise<ContactPage> => {
  const response = (await getEntryByUrl({
    contentTypeUid: "contact",
    entryUrl,
    referenceFieldPath: [
      "page_components.blog_banner.featured_blogs",
      "page_components.resource_grid.featured_resources",
    ],
    jsonRtePath: [
      "page_components.from_blog.featured_blogs.body",
      "page_components.resource_grid.featured_resources.resource",
    ],
  })) as ContactPage[];
  if (liveEdit) {
    addEditableTags(response[0], "contact", true);
  }

  return response[0];
};

export const getAboutRes = async (entryUrl: string): Promise<Page> => {
  const response = (await getEntryByUrl({
    contentTypeUid: "about",
    entryUrl,
    referenceFieldPath: [
      "page_components.blog_banner.featured_blogs",
      "page_components.resource_grid.featured_resources",
    ],
    jsonRtePath: [
      "page_components.from_blog.featured_blogs.body",
      "page_components.resource_grid.featured_resources.resource",
    ],
  })) as Page[];
  if (liveEdit) {
    addEditableTags(response[0], "about", true);
  }

  return response[0];
};

export const getCompanyOverviewRes = async (
  entryUrl: string
): Promise<Page> => {
  const response = (await getEntryByUrl({
    contentTypeUid: "company_overview",
    entryUrl,
    referenceFieldPath: [
      "page_components.blog_banner.featured_blogs",
      "page_components.resource_grid.featured_resources",
    ],
    jsonRtePath: [
      "page_components.from_blog.featured_blogs.body",
      "page_components.resource_grid.featured_resources.resource",
    ],
  })) as Page[];

  if (liveEdit) {
    addEditableTags(response[0], "page", true);
  }

  return response[0];
};

export const getLocationOverviewRes = async (
  entryUrl: string
): Promise<LocationsOverviewPage> => {
  const response = (await getEntryByUrl({
    contentTypeUid: "locations_overview",
    entryUrl,
    referenceFieldPath: [],
    jsonRtePath: [],
  })) as LocationsOverviewPage[];
  if (liveEdit) {
    addEditableTags(response[0], "locations_overview", true);
  }

  return response[0];
};

export const getMetalSelectorOverviewRes = async (
  entryUrl: string
): Promise<MetalSelectorOverviewPage> => {
  const response = (await getEntryByUrl({
    contentTypeUid: "metal_selector_overview",
    entryUrl,
    referenceFieldPath: [],
    jsonRtePath: [],
  })) as MetalSelectorOverviewPage[];
  if (liveEdit) {
    addEditableTags(response[0], "metal_selector_overview", true);
  }
  return response[0];
};

export const getCertificationOverviewRes = async (
  entryUrl: string
): Promise<CertificationsOverviewPage> => {
  const response = (await getEntryByUrl({
    contentTypeUid: "certifications_overview",
    entryUrl,
    referenceFieldPath: [],
    jsonRtePath: [],
  })) as CertificationsOverviewPage[];
  if (liveEdit) {
    addEditableTags(response[0], "certifications_overview", true);
  }

  return response[0];
};

export const getProductRes = async (entryUrl: string): Promise<ProductRes> => {
  const response = (await getEntryByUrl({
    contentTypeUid: "product",
    entryUrl,
    referenceFieldPath: [
      "tab_container.additional_resources.featured_resources",
    ],
    jsonRtePath: [],
  })) as ProductRes[];
  if (liveEdit) {
    addEditableTags(response[0], "product", true);
  }

  return response[0];
};

export const getTaxonomyRes = async (
  taxonomy_uid: string
): Promise<TaxonomyRes[]> => {
  try {
    const response = await getTaxonomy(taxonomy_uid); // Await the promise to get the resolved array

    const sortedResponse = response.sort((a, b) => {
      const depthA = Number(a.depth);
      const depthB = Number(b.depth);

      if (depthA !== depthB) {
        return depthA - depthB; // Ascending order by depth
      }

      return (a.order || 0) - (b.order || 0); // Ascending order by order, with a fallback to 0 if order is undefined
    });

    return sortedResponse; // Return the sorted response
  } catch (error) {
    console.error("Error fetching taxonomy:", error);
    return []; // Return an empty array in case of an error
  }
};

export const getArticleRes = async (entryUrl: string): Promise<ArticleRes> => {
  const response = (await getEntryByUrl({
    contentTypeUid: "article",
    entryUrl,
    referenceFieldPath: ["page_components.resource_grid.featured_resources"],
    jsonRtePath: [
      "page_components.from_blog.featured_blogs.body",
      "page_components.resource_grid.featured_resources.resource",
    ],
  })) as ArticleRes[];
  if (liveEdit) {
    addEditableTags(response[0], "article", true);
  }

  return response[0];
};

export const GetCaseStudyRes = async (
  entryUrl: string
): Promise<CaseStudyRes> => {
  const response = (await getEntryByUrl({
    contentTypeUid: "case_study",
    entryUrl,
    referenceFieldPath: ["page_components.resource_grid.featured_resources"],
    jsonRtePath: [
      "page_components.from_blog.featured_blogs.body",
      "page_components.resource_grid.featured_resources.resource",
    ],
  })) as ArticleRes[];
  if (liveEdit) {
    addEditableTags(response[0], "case_study", true);
  }

  return response[0];
};

export const getEventRes = async (entryUrl: string): Promise<EventRes> => {
  const response = (await getEntryByUrl({
    contentTypeUid: "event",
    entryUrl,
    referenceFieldPath: ["page_components.resource_grid.featured_resources"],
    jsonRtePath: [
      "page_components.from_blog.featured_blogs.body",
      "page_components.resource_grid.featured_resources.resource",
    ],
  })) as EventRes[];
  if (liveEdit) {
    addEditableTags(response[0], "event", true);
  }

  return response[0];
};

export const getBlogListRes = async (): Promise<{
  archivedBlogs: ResourcesRes[];
  recentBlogs: ResourcesRes[];
}> => {
  const response = (await getEntry({
    contentTypeUid: "blog_post",
    referenceFieldPath: ["author", "related_post"],
    jsonRtePath: ["body"],
  })) as ResourcesRes[][];
  if (liveEdit) {
    response[0].forEach((entry) => addEditableTags(entry, "blog_post", true));
  }
  const archivedBlogs = [] as ResourcesRes[];
  const recentBlogs = [] as ResourcesRes[];

  response[0].forEach((blogs) => {
    recentBlogs.push(blogs);
  });
  return { archivedBlogs, recentBlogs };
};

export const getResourceType = async (
  entryUrl: string
): Promise<ResourcesRes> => {
  const response = (await getEntryByUrl({
    contentTypeUid: "blog_post",
    entryUrl,
    referenceFieldPath: ["author", "related_post"],
    jsonRtePath: ["body", "related_post.body"],
  })) as ResourcesRes[];
  if (liveEdit) {
    addEditableTags(response[0], "blog_post", true);
  }
  return response[0];
};

export const getCertificationsRes = async (): Promise<CertificationsRes[]> => {
  const response = (await getEntry({
    contentTypeUid: "certification",
    referenceFieldPath: ["certification"],
    jsonRtePath: [],
  })) as CertificationsRes[];
  if (liveEdit) {
    addEditableTags(response[0], "certifications", true);
  }

  return response;
};

export const getLocationRes = async (): Promise<LocationRes[]> => {
  const response = (await getLocations({
    referenceFieldPath: ["taxonomies"],
    jsonRtePath: [],
  })) as LocationRes[];
  if (liveEdit) {
    addEditableTags(response[0], "location", true);
    }
    console.log(response);
  return response;
};

export const getResourcesRes = async (): Promise<ResourcesRes[]> => {
  const response = (await getLocations({
    referenceFieldPath: ["taxonomies"],
    jsonRtePath: [],
  })) as ResourcesRes[];
  if (liveEdit) {
    addEditableTags(response[0], "location", true);
  }
  return response;
};

export const getResourcesOverviewRes = async (
  entryUrl: string
): Promise<ResourcesOverviewPage> => {
  const response = (await getEntryByUrl({
    contentTypeUid: "resources_overview",
    entryUrl,
    referenceFieldPath: ["case_studies"],
    jsonRtePath: [],
  })) as ResourcesOverviewPage[];
  if (liveEdit) {
    addEditableTags(response[0], "resources_overview", true);
  }

  return response[0];
};

export const getLocationDetail = async (
  entryUrl: string
): Promise<LocationRes> => {
  const response = (await getEntryByUrl({
    contentTypeUid: "location",
    entryUrl,
    referenceFieldPath: ["case_studies"],
    jsonRtePath: [],
  })) as LocationRes[];
  if (liveEdit) {
    addEditableTags(response[0], "location", true);
  }

  return response[0];
};

// The main function to fetch all resources
export const getAllResources = async (
  taxonomies?: [{ taxonomy_uid: string; term_uid: string }]
) => {
  // Execute all the fetching functions in parallel using the separate functions defined above
  const webinarList = getWebinarList(taxonomies);
  const whitePaperList = getWhitePaperList(taxonomies);
  const articleList = getArticleList(taxonomies);
  const caseStudyList = getCaseStudyList(taxonomies);
  const videoList = getVideoList(taxonomies);
  const newsList = getNewsList(taxonomies);
  const eventList = getEventList(taxonomies);

  // Wait for all the promises to resolve
  const results = await Promise.all([
    webinarList,
    whitePaperList,
    articleList,
    caseStudyList,
    videoList,
    newsList,
    eventList,
  ]);

  // Combine and return the flattened results
  return results.flat();
};

export const getWhitePaperDetail = async (
  entryUrl: string
): Promise<WhitePaperRes> => {
  const response = (await getEntryByUrl({
    contentTypeUid: "white_paper",
    entryUrl,
    referenceFieldPath: ["case_studies"],
    jsonRtePath: [],
  })) as WhitePaperRes[];
  if (liveEdit) {
    addEditableTags(response[0], "white_paper", true);
  }

  return response[0];
};

export const getWebinarList = async (
  taxonomies?: [{ taxonomy_uid: string; term_uid: string }]
): Promise<ResourcesRes[]> => {
  const response = (await getEntry({
    contentTypeUid: "webinar",
    referenceFieldPath: [],
    jsonRtePath: [],
    taxonomies,
  })) as ResourcesRes[];
  if (liveEdit) {
    addEditableTags(response[0], "webinar", true);
  }

  return response;
};

export const getWhitePaperList = async (
  taxonomies?: [{ taxonomy_uid: string; term_uid: string }]
): Promise<ResourcesRes[]> => {
  const response = (await getEntry({
    contentTypeUid: "white_paper",
    referenceFieldPath: [],
    jsonRtePath: [],
    taxonomies,
  })) as ResourcesRes[];
  if (liveEdit) {
    addEditableTags(response[0], "white_paper", true);
  }

  return response;
};

export const getArticleList = async (
  taxonomies?: [{ taxonomy_uid: string; term_uid: string }]
): Promise<ResourcesRes[]> => {
  const response = (await getEntry({
    contentTypeUid: "article",
    referenceFieldPath: [],
    jsonRtePath: [],
    taxonomies,
  })) as ResourcesRes[];
  if (liveEdit) {
    addEditableTags(response[0], "article", true);
  }

  return response;
};

export const getCaseStudyList = async (
  taxonomies?: [{ taxonomy_uid: string; term_uid: string }]
): Promise<ResourcesRes[]> => {
  const response = (await getEntry({
    contentTypeUid: "case_study",
    referenceFieldPath: [],
    jsonRtePath: [],
    taxonomies,
  })) as ResourcesRes[];
  if (liveEdit) {
    addEditableTags(response[0], "case_study", true);
  }

  return response;
};

export const getVideoList = async (
  taxonomies?: [{ taxonomy_uid: string; term_uid: string }]
): Promise<ResourcesRes[]> => {
  const response = (await getEntry({
    contentTypeUid: "video",
    referenceFieldPath: [],
    jsonRtePath: [],
    taxonomies,
  })) as ResourcesRes[];
  if (liveEdit) {
    addEditableTags(response[0], "video", true);
  }

  return response;
};

export const getNewsList = async (
  taxonomies?: [{ taxonomy_uid: string; term_uid: string }]
): Promise<ResourcesRes[]> => {
  const response = (await getEntry({
    contentTypeUid: "news",
    referenceFieldPath: [],
    jsonRtePath: [],
    taxonomies,
  })) as ResourcesRes[];
  if (liveEdit) {
    addEditableTags(response[0], "news", true);
  }

  return response;
};

export const getEventList = async (
  taxonomies?: [{ taxonomy_uid: string; term_uid: string }]
): Promise<ResourcesRes[]> => {
  const response = (await getEntry({
    contentTypeUid: "event",
    referenceFieldPath: [],
    jsonRtePath: [],
    taxonomies,
  })) as ResourcesRes[];
  if (liveEdit) {
    addEditableTags(response[0], "event", true);
  }

  return response;
};
