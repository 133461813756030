/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as Utils from "@contentstack/utils";
import axios, { AxiosResponse } from "axios";
import ContentstackLivePreview from "@contentstack/live-preview-utils";
import {
  customHostUrl,
  initializeContentStackSdk,
  isValidCustomHostUrl,
} from "./utils";
import { Environments, EnvironmentUrlResponse, Locale } from "../typescript/response";
import { TaxonomyRes } from "../typescript/pages";
import i18n from "./i18n";
import { DEFAULT_LOCALE } from "../typescript/constants";
import { GetEntry, GetEntryByUrl, GetLocationsByBrand } from "../typescript/response";

const {
  REACT_APP_CONTENTSTACK_API_HOST,
  REACT_APP_CONTENTSTACK_API_KEY,
  REACT_APP_CONTENTSTACK_APP_HOST,
  REACT_APP_CONTENTSTACK_MANAGEMENT_TOKEN,
  REACT_APP_CONTENTSTACK_BRAND,
} = process.env;

const customHostBaseUrl = REACT_APP_CONTENTSTACK_API_HOST
  ? customHostUrl(REACT_APP_CONTENTSTACK_API_HOST as string)
  : "";

// SDK initialization
const Stack = initializeContentStackSdk();

// set host url only for custom host or non prod base url's
if (customHostBaseUrl && isValidCustomHostUrl(customHostBaseUrl)) {
  Stack.setHost(customHostBaseUrl);
}

// Setting LP if enabled
ContentstackLivePreview.init({
  //@ts-ignore
  stackSdk: Stack,
  clientUrlParams: {
    host: REACT_APP_CONTENTSTACK_APP_HOST,
  },
})?.catch((error) => console.error(error));

export const { onEntryChange } = ContentstackLivePreview;

const renderOption = {
  span: (node: any, next: any) => next(node.children),
};

/**
 *
 * fetches all the entries from specific content-type
 * @param {* content-type uid} contentTypeUid
 * @param {* reference field name} referenceFieldPath
 * @param {* Json RTE path} jsonRtePath
 *
 */
export const getEntry = ({
  contentTypeUid,
  referenceFieldPath,
  jsonRtePath,
  taxonomies,
}: GetEntry) => {
  const urlParams = new URLSearchParams(window.location.search);
  const urlLocale = urlParams.get("locale");

  // Determine the locale to use
  const locale = urlLocale ?? i18n.language ?? DEFAULT_LOCALE;

  return new Promise((resolve, reject) => {
    const query = Stack.ContentType(contentTypeUid).Query();

    if (referenceFieldPath) query.includeReference(referenceFieldPath);

    if (REACT_APP_CONTENTSTACK_BRAND) {
      query.where("taxonomies.brand", REACT_APP_CONTENTSTACK_BRAND);
    } else {
      query.where("taxonomies.brand", "form_technologies");
    }

    // Filter out any taxonomies where taxonomy_uid is "brand"
    const filteredTaxonomies =
      taxonomies?.filter((taxonomy) => taxonomy.taxonomy_uid !== "brand") || [];

    // Add OR condition based on taxonomies if provided
    if (filteredTaxonomies && filteredTaxonomies.length > 0) {
      const orConditions = filteredTaxonomies.map((taxonomy) => {
        return Stack.ContentType(contentTypeUid)
          .Query()
          .where(`taxonomies.${taxonomy.taxonomy_uid}`, taxonomy.term_uid);
      });

      // Combine all taxonomy-based conditions using OR
      query.or(...orConditions);
    }

    query.language(locale);

    query
      .includeFallback()
      .toJSON()
      .find()
      .then(
        (result) => {
          jsonRtePath &&
            Utils.jsonToHTML({
              entry: result,
              paths: jsonRtePath,
              renderOption,
            });
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
  });
};

/**
 *fetches specific entry from a content-type
 *
 * @param {* content-type uid} contentTypeUid
 * @param {* url for entry to be fetched} entryUrl
 * @param {* reference field name} referenceFieldPath
 * @param {* Json RTE path} jsonRtePath
 * @returns
 */
export const getEntryByUrl = ({
  contentTypeUid,
  entryUrl,
  referenceFieldPath,
  jsonRtePath,
}: GetEntryByUrl) => {
  const urlParams = new URLSearchParams(window.location.search);
  const urlLocale = urlParams.get("locale");
  const locale = urlLocale ?? i18n.language ?? DEFAULT_LOCALE;

  return new Promise((resolve, reject) => {
    const blogQuery = Stack.ContentType(contentTypeUid).Query();
    if (referenceFieldPath) blogQuery.includeReference(referenceFieldPath);

    if (REACT_APP_CONTENTSTACK_BRAND) {
      blogQuery.where("taxonomies.brand", REACT_APP_CONTENTSTACK_BRAND);
    } else {
      blogQuery.where("taxonomies.brand", "form_technologies");
    }

    blogQuery.language(locale);
    blogQuery.includeFallback();

    blogQuery.toJSON();
    blogQuery.where("url", `${entryUrl}`);

    const data = blogQuery.find();
    data.then(
      (result) => {
        jsonRtePath &&
          Utils.jsonToHTML({
            entry: result,
            paths: jsonRtePath,
            renderOption,
          });
        resolve(result[0]);
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
};

export const searchByQueryString = async (search: string) => {
  try {
    const listSearchContentType = [
      "page",
      "about",
      "location",
      "locations_overview",
      "resources_overview",
      "case_study",
      "certification",
      "certifications_overview",
      "contact",
    ];
    const searchField = ["title", "sub_title", "description"];
    const requests = listSearchContentType.map((contentType) => {
      const conditions = searchField.map((field) =>
        Stack.ContentType(contentType).Query().regex(field, search, "i")
      );
      return Stack.ContentType(contentType)
        .Query()
        .only([
          "title",
          "sub_title",
          "description",
          "url",
          "seo.meta_title",
          "seo.meta_description",
        ])
        .or(...conditions)
        .toJSON()
        .find();
    });

    const response = await Promise.all(requests);
    return response.flat(2);
  } catch (error) {
    console.error("Error search:", error);
    return [];
  }
};

export const getLocations = ({
  referenceFieldPath,
  jsonRtePath,
}: GetLocationsByBrand) => {
  return new Promise((resolve, reject) => {
    const locationQuery = Stack.ContentType("location").Query();

    if (referenceFieldPath) locationQuery.includeReference(referenceFieldPath);

    if (REACT_APP_CONTENTSTACK_BRAND) {
      locationQuery.where("taxonomies.brand", REACT_APP_CONTENTSTACK_BRAND);
    } else {
      locationQuery.where("taxonomies.brand", "form_technologies");
    }

    const data = locationQuery.toJSON().find();
    data.then(
      (result) => {
        // Check if the result is an array with a single element and unwrap it
        const unwrappedResult =
          Array.isArray(result) && result.length === 1 ? result[0] : result;

        jsonRtePath &&
          Utils.jsonToHTML({
            entry: unwrappedResult,
            paths: jsonRtePath,
            renderOption,
          });

        resolve(unwrappedResult); // Resolve with the unwrapped result
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
};

//Try to refactor into API

interface LocalesResponse {
  locales: Locale[];
}

export const getLocales = async (): Promise<Locale[]> => {
  try {
    const url = "https://azure-na-api.contentstack.com/v3/locales";
    const response: AxiosResponse<LocalesResponse> = await axios.get(url, {
      headers: {
        api_key: REACT_APP_CONTENTSTACK_API_KEY,
        authorization: REACT_APP_CONTENTSTACK_MANAGEMENT_TOKEN,
        "Content-Type": "application/json",
      },
    });

    // Sort locales alphabetically based on the 'name' property
    const sortedLocales = response.data.locales.sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    return sortedLocales;
  } catch (error) {
    console.error("Error fetching locales:", error);

    // Optionally rethrow or return an empty array depending on how you want to handle errors
    return [];
  }
};

export const getTaxonomy = async (
  taxonomy_uid: string
): Promise<TaxonomyRes[]> => {
  try {
    const url =
      "https://azure-na-api.contentstack.com/v3/taxonomies/" +
      taxonomy_uid +
      "/terms?depth=0&include_order=true";
    const response = await axios.get(url, {
      headers: {
        api_key: REACT_APP_CONTENTSTACK_API_KEY,
        authorization: REACT_APP_CONTENTSTACK_MANAGEMENT_TOKEN,
        "Content-Type": "application/json",
      },
    });

    return response.data.terms;
  } catch (error) {
    console.error("Error fetching Taxonomies:", error);
    return [];
  }
};

export const getLocaleFromEnvironment = async (): Promise<string | undefined> => {
  try {
    const baseUrl = window.location.hostname;
    const url = "https://azure-na-api.contentstack.com/v3/environments";
    const response: AxiosResponse<{ environments: Environments[] }> = await axios.get(url, {
      headers: {
        api_key: REACT_APP_CONTENTSTACK_API_KEY,
        authorization: REACT_APP_CONTENTSTACK_MANAGEMENT_TOKEN,
        "Content-Type": "application/json",
      },
    });

    // Assuming you want to fetch the first environment configuration
    const environmentConfig = response.data.environments[0];
    console.log("🚀 ~ getLocaleFromEnvironment ~ environmentConfig:", environmentConfig);
    const matchingEnv = environmentConfig.urls.find((env: EnvironmentUrlResponse) =>
            env.url && env.url.includes(baseUrl)
          );
    return matchingEnv?.locale ? matchingEnv.locale : DEFAULT_LOCALE;
  } catch (error) {
    console.error("Error fetching environment configuration:", error);
    throw error;
  }
};
