import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import styles from "../styles/SidebarModal.module.css";

interface SidebarModalProps {
  content: JSX.Element;
  sidebarMaxWidth?: number;
  closeButton?: boolean;
}

function SidebarModal({
  content,
  sidebarMaxWidth = 248,
  closeButton = true,
}: SidebarModalProps) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const sideBarTheme = createTheme({
    zIndex: {
      drawer: 100000,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1280,
      },
    },
  });

  const plusIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M1 10H19"
        stroke="var(--brand-primary-500)"
        strokeLinecap="round"
      />
      <path
        d="M10 1L10 19"
        stroke="var(--brand-primary-500)"
        strokeLinecap="round"
      />
    </svg>
  );

  return (
    <>
      <ThemeProvider theme={sideBarTheme}>
        <div className={styles.filterContainer} onClick={handleDrawerToggle}>
          <div className={styles.filterButtonWrapper}>
            <p>Filter</p>
            {plusIcon}
          </div>
        </div>
        <Box sx={{ width: { sm: sidebarMaxWidth }, flexShrink: { sm: 0 } }}>
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onTransitionEnd={handleDrawerTransitionEnd}
            onClose={handleDrawerClose}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {closeButton && (
              <div className={styles.closeButtonWrapper}>
                <img
                  className={styles.closeButton}
                  src="/closex.svg"
                  alt="Close"
                  onClick={handleDrawerClose}
                />
              </div>
            )}
            {content}
          </Drawer>
          <div className={styles.content}>{content}</div>
        </Box>
      </ThemeProvider>
    </>
  );
}

export default SidebarModal;
