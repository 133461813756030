import React from "react";
import styles from "../styles/SmallCard.module.css";
import { SmallCard as SmallCardProp } from "../typescript/components";

export default function SmallCard({
  small_card,
  remove_overlay,
}: {
  small_card: SmallCardProp;
  remove_overlay?: boolean;
}) {
  const hasBackgroundImage =
    small_card.background && small_card.background.url !== undefined;
  const backgroundStyle =
    small_card.background && small_card.background.url
      ? { backgroundImage: `url(${small_card.background.url})` }
      : {};

  return (
    <div
      className={`${styles.cardContainer} ${remove_overlay ? styles.noOverlay : styles.hasOverlay} ${hasBackgroundImage ? styles.withBackground : styles.withOutBackground}`}
      style={backgroundStyle}
    >
      <h5 className={styles.title}>{small_card.title}</h5>
      <div className={styles.smallSquareCard}>
        <div className={styles.title2}>{small_card.title}</div>
        <div className={styles.description}>{small_card.description}</div>
        {small_card.cta?.cta_title && small_card.cta?.cta_link && (
          <a
            href={`${small_card.cta.cta_link}${small_card.cta.contact_type ? `?form_type=${small_card.cta.contact_type}` : ""}`}
            className={styles.link}
          >
            {small_card.cta.cta_title}
          </a>
        )}
      </div>
    </div>
  );
}
