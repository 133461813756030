import React from "react";
import { ResuorceAsideProps } from "../typescript/components";
import styles from "../styles/ResuorceAside.module.css";

export default function ResourceAside({
  resource_aside,
}: {
  resource_aside: ResuorceAsideProps;
}) {
  const { component_title, articles } = resource_aside;
  return (
    <div className={styles.container}>
      <div className={styles.resourcesAside}>
        {component_title && (
          <div className={styles.resourcesAsideTitle}>{component_title}</div>
        )}
        {articles?.length &&
          articles.map((article, index) => (
            <div key={index} className={styles.resourcesAsideSnippet}>
              <div className={styles.resourcesAsideSnippetTitleContainer}>
                <img
                  src={article?.article_thumbnail?.media?.url}
                  loading="lazy"
                  alt={article?.article_title.text}
                />
                <div className={styles.resourcesAsideSnippetTitle}>
                  {article?.article_title?.text}
                </div>
              </div>
              <div className={styles.snippetContentContainer}>
                <div className={styles.snippetSubTitle}>
                  {article?.article_subtitle?.text}
                </div>
                <a
                  href={article?.cta_link?.title || "/"}
                  className={styles.snippetCTA}
                >
                  {article?.cta_link?.title}
                </a>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
