import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import styles from "../styles/AsideFilter.module.css";
import { AsideFilter as AsideFilterProp } from "../typescript/components";

export default function AsideFilter({
  filter,
  onApplyFilters,
}: {
  filter: AsideFilterProp;
  onApplyFilters: (selectedFilters: any) => void;
}) {
  const [isExpanded, setIsExpanded] = useState<string | null>(null);

  // State to track selected filters
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [selectedTechnologies, setSelectedTechnologies] = useState<string[]>(
    []
  );
  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const [selectedMetals, setSelectedMetals] = useState<string[]>([]);
  const [selectedIndustries, setSelectedIndustries] = useState<string[]>([]);
  const [selectedFinishes, setSelectedFinishes] = useState<string[]>([]);

  // Toggle function to change the expanded state for a specific section
  const toggleExpand = (uid: string) => {
    setIsExpanded(isExpanded === uid ? null : uid); // Toggle expand/collapse
  };

  // Handle changes to metals checkboxes
  const handleFilterChange = (
    uid: string,
    handler: Dispatch<SetStateAction<string[]>>
  ) => {
    handler((prevSelected) =>
      prevSelected.includes(uid)
        ? prevSelected.filter((id) => id !== uid)
        : [...prevSelected, uid]
    );
  };

  useEffect(() => {
    const selectedFilters = {
      type: selectedTypes,
      technology: selectedTechnologies,
      service: selectedServices,
      metal: selectedMetals,
      industry: selectedIndustries,
      finishes: selectedFinishes,
    };
    onApplyFilters(selectedFilters);
  }, [
    selectedTypes,
    selectedMetals,
    selectedTechnologies,
    selectedServices,
    selectedIndustries,
    selectedFinishes,
  ]);
  // Function to gather selected filters and pass to parent

  // Function to clear all filters and reset inputs
  const clearFilters = () => {
    // Clear all selected filters
    setSelectedTypes([]);
    setSelectedTechnologies([]);
    setSelectedServices([]);
    setSelectedMetals([]);
    setSelectedIndustries([]);
    setSelectedFinishes([]);

    // Apply filters with empty selections
    onApplyFilters({
      type: [],
      technology: [],
      services: [],
      metals: [],
      industries: [],
      finishes: [],
    });
  };

  const renderToggleIcon = (uid: string) => {
    if (isExpanded === uid) {
      return (
        <svg
          width="10"
          height="8"
          viewBox="0 0 10 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Minus Icon">
            <path
              id="Line 10"
              d="M1 4.00031H9"
              stroke="#2273BA"
              strokeLinecap="round"
            />
          </g>
        </svg>
      );
    } else {
      return (
        <svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Plus Icon">
            <path
              id="Line 10"
              d="M1 5.00024H9"
              stroke="#2273BA"
              strokeLinecap="round"
            />
            <path
              id="Line 11"
              d="M5 1.00024L5 9.00024"
              stroke="#2273BA"
              strokeLinecap="round"
            />
          </g>
        </svg>
      );
    }
  };

  return (
    <div className={styles.aboutBanner}>
      <div className={styles.root}>
        <section className={styles.locationsOptions}>
          <div className={styles.categoryOptions}>
            <div className={styles.category}>
              <a className={styles.filters}>FILTERS</a>
            </div>
            <div className={styles.clearAllFiltersButton}>
              <a className={styles.clearAllFilters} onClick={clearFilters}>
                Clear All Filters
              </a>
            </div>
          </div>
          <div className={styles.filterOptions}>
                      <div className={styles.locationFilter}>
                {/* By Type */}
                        {filter.types && filter.types.length > 0 && (
                <div className={styles.taxonomySelect}>
                    <div className={styles.filterHeaderWrapper}>
                        <div className={styles.filterHeader}>By Types</div>
                        <div
                            className={styles.toggle}
                            onClick={() => toggleExpand("technology")}
                        >
                            {renderToggleIcon("technology")}
                        </div>
                    </div>
                    {isExpanded === "technology" && (
                        <div className={styles.optionsWrapper}>
                                        {filter.types.map((technology) => (
                                <label
                                    key={technology.uid}
                                    className={styles.locationSelectOptions}
                                >
                                    <input
                                        type="checkbox"
                                        value={technology.uid}
                                        onChange={() =>
                                            handleFilterChange(
                                                technology.uid,
                                                setSelectedTechnologies
                                            )
                                        }
                                        checked={selectedTechnologies.includes(
                                            technology.uid
                                        )}
                                    />
                                    <span className={styles.selectBoxCheck}></span>
                                    <span className={styles.country}>
                                        {technology.name}
                                    </span>
                                </label>
                            ))}
                        </div>
                    )}
                </div>
            )}
              {/* By Technology */}
              {filter.technology && filter.technology.length > 0 && (
                <div className={styles.taxonomySelect}>
                  <div className={styles.filterHeaderWrapper}>
                    <div className={styles.filterHeader}>By Technology</div>
                    <div
                      className={styles.toggle}
                      onClick={() => toggleExpand("technology")}
                    >
                      {renderToggleIcon("technology")}
                    </div>
                  </div>
                  {isExpanded === "technology" && (
                    <div className={styles.optionsWrapper}>
                      {filter.technology.map((technology) => (
                        <label
                          key={technology.uid}
                          className={styles.locationSelectOptions}
                        >
                          <input
                            type="checkbox"
                            value={technology.uid}
                            onChange={() =>
                              handleFilterChange(
                                technology.uid,
                                setSelectedTechnologies
                              )
                            }
                            checked={selectedTechnologies.includes(
                              technology.uid
                            )}
                          />
                          <span className={styles.selectBoxCheck}></span>
                          <span className={styles.country}>
                            {technology.name}
                          </span>
                        </label>
                      ))}
                    </div>
                  )}
                </div>
              )}
              {/* By Industry */}
              {filter.industries && filter.industries.length > 0 && (
                <div className={styles.taxonomySelect}>
                  <div className={styles.filterHeaderWrapper}>
                    <div className={styles.filterHeader}>By Industry</div>
                    <div
                      className={styles.toggle}
                      onClick={() => toggleExpand("industry")}
                    >
                      {renderToggleIcon("industry")}
                    </div>
                  </div>
                  {isExpanded === "industry" && (
                    <div className={styles.optionsWrapper}>
                      {filter.industries.map((industry) => (
                        <label
                          key={industry.uid}
                          className={styles.locationSelectOptions}
                        >
                          <input
                            type="checkbox"
                            value={industry.uid}
                            onChange={() =>
                              handleFilterChange(
                                industry.uid,
                                setSelectedIndustries
                              )
                            }
                            checked={selectedIndustries.includes(industry.uid)}
                          />
                          <span className={styles.selectBoxCheck}></span>
                          <span className={styles.country}>
                            {industry.name}
                          </span>
                        </label>
                      ))}
                    </div>
                  )}
                </div>
              )}
              {/* By Finishes */}
              {filter.finishes && filter.finishes.length > 0 && (
                <div className={styles.taxonomySelect}>
                  <div className={styles.filterHeaderWrapper}>
                    <div className={styles.filterHeader}>By Finishes</div>
                    <div
                      className={styles.toggle}
                      onClick={() => toggleExpand("finishes")}
                    >
                      {renderToggleIcon("finishes")}
                    </div>
                  </div>
                  {isExpanded === "finishes" && (
                    <div className={styles.optionsWrapper}>
                      {filter.finishes.map((industry) => (
                        <label
                          key={industry.uid}
                          className={styles.locationSelectOptions}
                        >
                          <input
                            type="checkbox"
                            value={industry.uid}
                            onChange={() =>
                              handleFilterChange(
                                industry.uid,
                                setSelectedIndustries
                              )
                            }
                            checked={selectedIndustries.includes(industry.uid)}
                          />
                          <span className={styles.selectBoxCheck}></span>
                          <span className={styles.country}>
                            {industry.name}
                          </span>
                        </label>
                      ))}
                    </div>
                  )}
                </div>
              )}
              {/* By Metal */}
              {filter.metals && filter.metals.length > 0 && (
                <div className={styles.taxonomySelect}>
                  <div className={styles.filterHeaderWrapper}>
                    <div className={styles.filterHeader}>By Metal</div>
                    <div
                      className={styles.toggle}
                      onClick={() => toggleExpand("metal")}
                    >
                      {renderToggleIcon("metal")}
                    </div>
                  </div>
                  {isExpanded === "metal" && (
                    <div className={styles.optionsWrapper}>
                      {filter.metals.map((metal) => (
                        <label
                          key={metal.uid}
                          className={styles.locationSelectOptions}
                        >
                          <input
                            type="checkbox"
                            value={metal.uid}
                            onChange={() =>
                              handleFilterChange(metal.uid, setSelectedMetals)
                            }
                            checked={selectedMetals.includes(metal.uid)}
                          />
                          <span className={styles.selectBoxCheck}></span>
                          <span className={styles.country}>{metal.name}</span>
                        </label>
                      ))}
                    </div>
                  )}
                </div>
              )}
              {/* By Service */}
              {filter.services && filter.services.length > 0 && (
                <div className={styles.taxonomySelect}>
                  <div className={styles.filterHeaderWrapper}>
                    <div className={styles.filterHeader}>By Service</div>
                    <div
                      className={styles.toggle}
                      onClick={() => toggleExpand("service")}
                    >
                      {renderToggleIcon("service")}
                    </div>
                  </div>
                  {isExpanded === "service" && (
                    <div className={styles.optionsWrapper}>
                      {filter.services.map((service) => (
                        <label
                          key={service.uid}
                          className={styles.locationSelectOptions}
                        >
                          <input
                            type="checkbox"
                            value={service.uid}
                            onChange={() =>
                              handleFilterChange(
                                service.uid,
                                setSelectedServices
                              )
                            }
                            checked={selectedServices.includes(service.uid)}
                          />
                          <span className={styles.selectBoxCheck}></span>
                          <span className={styles.country}>{service.name}</span>
                        </label>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
