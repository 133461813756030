
import React from "react"
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { LivePreviewProvider } from "./context/live-preview-context-provider";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from 'react-i18next';
import i18n from "./sdk/i18n";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <LivePreviewProvider>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </LivePreviewProvider>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
